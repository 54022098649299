import React from 'react'
import AppComponent from '../AppComponent'

const Report = React.lazy(() => import('./Report'))
const Custom = React.lazy(() => import('./Custom'))
const Gene = React.lazy(() => import('./Gene'))

class MarketIntelligence extends AppComponent {
  static appDescriptor = {
    name: 'Market Intelligence',
    url: '/app/marketintelligence',
    icon: 'icon-pie-chart',
    nav: {
      items: [
        {
          name: 'Package Report',
          url: '/app/marketintelligence/report',
          icon: 'icon-chart'
        },
        {
          name: 'Custom Report',
          url: '/app/marketintelligence/custom',
          icon: 'cui-monitor'
        },
        {
          name: 'Nucleus',
          url: '/app/marketintelligence/gene',
          icon: 'icon-people'
        }
      ]
    },
    routes: [
      { path: '/app/marketintelligence', exact: true, name: 'Market Intelligence', component: Report },
      { path: '/app/marketintelligence/report', name: 'Report', component: Report },
      { path: '/app/marketintelligence/custom', name: 'Custom', component: Custom },
      { path: '/app/marketintelligence/gene', name: 'Gene', component: Gene }
    ]
  }

  render () {
    return <div>Entering Eureka Market Intelligence ...</div>
  }
}

export default MarketIntelligence
