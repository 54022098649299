import React, { Fragment, Component } from 'react'

import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
// import '../styles.css'
import '../styles.scss'
import EditProductScope from './EditProductScope'
import axios from 'axios'
import config from 'common/config'
import { Label } from 'reactstrap'
import RemoveAlert from '../RemoveAlert'
import toast from 'cogo-toast'

class ManageSubScopes extends Component {
  constructor (props) {
    super(props)
    this.state = {
      ScopesList: [],
      isEditScopeOpen: false,
      editScopeModalData: null,
      visible: false,
      deleteItemDetail: {}
    }
  }

  componentDidMount (): void {
    this.getScopesList()
  }

  editScopeToggle = () => {
    this.setState({
      isEditScopeOpen: !this.state.isEditScopeOpen
    })
  }

    getScopesList = () => {
      console.log(' inside getScopesList')
      axios
        .get(config.AccountPermissionManager.manageProducts.url + '/' + this.props.productId + '/policy', {
          headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${sessionStorage.getItem('token')}`
          }
        })
        .then(response => {
          console.log(' inside manage product scopes response data is : ', JSON.stringify(response.data))
          let permissions = response.data
          let filteredResult = []
          for (let i = 0; i < permissions.length; i++) {
            if (permissions[i].name.split('_')[0] === 'PERMISSION') {
              let temp = {}
              temp['permissionId'] = permissions[i].id
              let scopeNameAndId = permissions[i].name.split(/_(.+)/)[1]
              temp['scopeName'] = scopeNameAndId.split('_')[0]
              temp['scopeId'] = scopeNameAndId.split('_')[1]
              temp['sNo'] = i + 1
              filteredResult.push(temp)
            }
          }
          this.setState({ ScopesList: filteredResult }, () => { console.log(' state is : ', this.state.ScopesList) })
        })
        .catch(err => console.log(err))
    }

    getScopeDetail = (e) => {
      let permission = JSON.parse(e.target.dataset.id)

      this.setState({ editScopeModalData: permission }, this.editScopeToggle)
    }

    toggle = () => {
      this.setState({
        isEditScopeOpen: !this.state.isEditScopeOpen
      })
    }

    removeScope = (deleteItemDetail) => {
      let productId = deleteItemDetail['productId']
      let scopeId = deleteItemDetail['scopeId']
      let scopeName = deleteItemDetail['deleteItemName']
      let permissionId = deleteItemDetail['permissionId']

      let data1 = {}
      data1['productId'] = productId
      data1['scopeId'] = scopeId
      data1['scopeName'] = scopeName
      let data2 = {}
      data2['productId'] = productId
      data2['permissionId'] = permissionId

      axios
        .delete(config.AccountPermissionManager.manageProducts.url + '/scope-delete', { data: data1 }, {
          headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${sessionStorage.getItem('token')}`
          }
        })
        .then(resp => {
          return resp
        })
        .then(resp => {
          let promises = []
          for (let i = 0; i < 2; i++) {
            promises.push(axios.delete(config.AccountPermissionManager.manageProducts.url + '/permission-delete', { data: data2 }, {
              headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                Authorization: `Bearer ${sessionStorage.getItem('token')}`
              }
            }))
          }
          axios.all(promises, {
            headers: {
              'Content-Type': 'application/json;charset=UTF-8',
              Authorization: `Bearer ${sessionStorage.getItem('token')}`
            }
          })
          this.getScopesList()
        })
        .then(resp => {
          toast.success('Successfully removed')
          this.getScopesList()
        })
        .catch(err => toast.error('Successfully removed'))
    }

    actionsFormatter = (cell, row) => {
      return (
        <Fragment>
          <Label className='edit-product-modal' data-id={JSON.stringify(row)} onClick={this.getScopeDetail}>Edit</Label> &nbsp;
          {/* <Label className='remove-product-modal' data-scopeid={row.scopeId} onClick={this.removeScope} data-name={row.scopeName} data-permissionid={row.permissionId}>Remove</Label> */}
          <Label className='remove-product-modal' data-scopeid={row.scopeId} onClick={(e) => { this.onDismiss(); this.setDeleteItemDetail(e) } }data-name={row.scopeName} data-permissionid={row.permissionId}>Remove</Label>
        </Fragment>
      )
    }

    onDismiss = () => {
      this.setState({ visible: !this.state.visible })
    }

    setDeleteItemDetail = (e) => {
      let deleteItemDetail = {}
      deleteItemDetail['productId'] = this.props.productId
      deleteItemDetail['deleteItemName'] = e.target.dataset.name
      deleteItemDetail['scopeId'] = e.target.dataset.scopeid
      deleteItemDetail['permissionId'] = e.target.dataset.permissionid
      this.setState({ deleteItemDetail: deleteItemDetail })
    }

    render () {
      return (

        <div>
          <BootstrapTable
            trClassName='manage-product-table-row'
            pagination
            data={this.state.ScopesList}
            striped
            search
            exportCSV
            hover
            version='4'
            ignoreSinglePage={true}
            headerContainerClass='manage-product-table-header'
          >
            <TableHeaderColumn isKey dataField="sNo"
              thStyle={{ width: '5%', color: 'white' }}
              tdStyle={{ width: '5%' }}
            >
                              #
            </TableHeaderColumn>
            <TableHeaderColumn dataField="scopeName" dataSort
              thStyle={{ width: '20%', color: 'white' }}
              tdStyle={{ width: '20%' }}
            >
                              Scope Name
            </TableHeaderColumn>
            <TableHeaderColumn dataFormat={this.actionsFormatter}
              thStyle={{ width: '15%', color: 'white' }}
              tdStyle={{ width: '15%' }}
            >
                              Actions
            </TableHeaderColumn>

          </BootstrapTable>
          <div>
            <EditProductScope
              editScopeToggle={this.editScopeToggle}
              isEditScopeOpen={this.state.isEditScopeOpen}
              productId={this.props.productId}
              permission={this.state.editScopeModalData}
            ></EditProductScope>

            <RemoveAlert
              visible={this.state.visible}
              toggle={this.onDismiss}
              deleteItemDetail = {this.state.deleteItemDetail}
              deleteFunction={this.removeScope}
            >
            </RemoveAlert>
          </div>
        </div>

      )
    }
}

export default ManageSubScopes
