export const getPageIndex = (value) => {
  switch (value) {
    case 'explorer':
      return 1
    case 'instantcheck':
      return 2
    case 'marketintelligence':
      return 3
    case 'pinpoint':
      return 4
    case 'privacyshield':
      return 5
    case 'rewardnow':
      return 6
    case 'matchbox':
      return 7
    case 'verification':
      return 8
    case 'splash':
      return 0
    default:
      return -1
  }
}
