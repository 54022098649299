import React, { Component, Fragment } from 'react'

import {
  Badge,
  Button, Card,
  Col,
  Form,
  FormGroup,
  FormText,
  Input,
  Label, Modal,
  ModalBody, ModalFooter,
  ModalHeader,
  Row,
  Table,
  Progress
} from 'reactstrap'
import CardHeader from 'reactstrap/es/CardHeader'
import CardBody from 'reactstrap/es/CardBody'

import Collapse from 'reactstrap/es/Collapse'
import axios from 'axios'
import toast from 'cogo-toast'
import './styles.scss'
import config from '../../../common/config'
import ProductAndScopes from './ProductAndScopes'
import ProgressBar from '../Products/AddProduct/ProgressBar'
// import ProgressBar from './ProgressBar'

class EditRole extends Component {
  constructor (props) {
    super(props)
    this.state = {
      accordionIndexOpen: 0,

      newRoleDetail: { },
      productAndScopesList: [],
      showProgressBar: false,
      progressQuote: '',
      progressCompleted: 0,
      hasError_name: true,
      hasError_description: true,
      hasError: false

    }
    this.progressCompleted = 0
    this.progressQuote = ''
  }

    onChange = (e) => {
      // let stateVar = e.target.dataset.stateVar
      let key = e.target.dataset.key
      let value = e.target.value.trim()
      if ((!this.isValidText(value, 'hasError_' + key))) {
        return null
      }

      this.setState(prevState => ({
        ...prevState,
        newRoleDetail: {
          ...prevState.newRoleDetail,
          [key]: value
        }
      }))
    }

    toggle = (e) => {
      let accordionIndex = Number(e.target.dataset.event)
      this.setState({
        accordionIndexOpen: accordionIndex
      })
    }

    setProgressBarVal = (quote, progress) => {
      this.progressCompleted = progress
      this.progressQuote = quote
    }

    progressBar = () => {
      let result = (
        <div>
          <div className='text-center'> Creating Role !! </div>
          <Progress value={this.progressCompleted}> </Progress>
        </div>
      )
      return result
    }

    toggleProgressBar = () => {
      this.setState({
        showProgressBar: !this.state.showProgressBar
      })
    }

    createNewRole = () => {
      if (this.state.hasError_name ||
        this.state.hasError_description
      ) {
        this.setState({ hasError: true })
        return null
      }
      this.setState({ hasError: false })

      let data = this.state.newRoleDetail
      this.toggleProgressBar()
      this.setProgressBarVal('Setting up your Product!! Please wait !!', 5)
      axios
        .post(config.AccountPermissionManager.manageRoles.url, data, {
          headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${sessionStorage.getItem('token')}`
          }
        })
        .then(response => {
          this.setProgressBarVal('Role Created!!', 50)
          this.setProgressBarVal('Adding Scopes!!', 55)
          if (response.status === 201) {
            // this.props.getRoleAndPrivilegesData()
            // this.props.addRoleToggle()
            let addRolePromises = []
            let products = this.state.productAndScopesList
            let scopeNumber = 1
            for (let i = 0; i < products.length; i++) {
              let scopes = products[i]['scopes']
              for (let j = 0; j < scopes.length; j++) {
                if (scopes[j]['permission'] === true) {
                  let payload = {}
                  payload['productId'] = products[i]['productId']
                  payload['scopeId'] = scopes[j]['id']
                  payload['scopeName'] = scopes[j]['name']
                  payload['roleName'] = data['name']
                  scopeNumber++

                  addRolePromises.push(axios
                    .post(config.AccountPermissionManager.manageRoles.url + '/add-permission', payload, {
                      headers: {
                        'Content-Type': 'application/json;charset=UTF-8',
                        Authorization: `Bearer ${sessionStorage.getItem('token')}`
                      }
                    }))
                }
              }
            }

            return axios.all(addRolePromises, {
              headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                Authorization: `Bearer ${sessionStorage.getItem('token')}`
              }
            }).then(results => {
              results.forEach((response, index) => {
                let newProgress = ((index + 1) / scopeNumber) * 100 - 50 + this.progressCompleted
                if (newProgress < 95) { this.setProgressBarVal('Adding Scope!!', newProgress) } else {
                  this.setProgressBarVal('Almost Finished!!', newProgress)
                }
              })
            })

              .then(response => {
                this.setProgressBarVal('Role Created Successfully!!', 100)
                this.props.getRoleAndPrivilegesData()
                this.props.addRoleToggle()
                this.getProductAndScopes()
                this.toggleProgressBar()
                this.setProgressBarVal('', 0)
                this.setState({ accordionIndexOpen: 0 })
                this.setHasErrorTrue()
                toast.success('Successfully created')
              })
              .catch(err => toast.error('Something went wrong'))
          }
        })
    }

    setHasErrorTrue = () => {
      this.setState({ hasError_name: true, hasError_description: true })
    }

    getProductAndScopes = () => {
      axios
        .get(config.AccountPermissionManager.manageProducts.url, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`
          }
        })
        .then(response => {
          let products = response.data
          // let productAndScopesList = []
          for (let i = 0; i < products.length; i++) {
            let productAndScopes = {}
            products[i]['productName'] = products[i].name
            products[i]['productId'] = products[i].id
            let scopes = products[i]['scopes']
            let scopeAndPermissionList = []
            for (let j = 0; j < scopes.length; j++) {
              let scopeAndPermission = {}
              scopeAndPermission['id'] = scopes[j].id
              scopeAndPermission['name'] = scopes[j].name
              scopeAndPermission['permission'] = false
              scopeAndPermissionList.push(scopeAndPermission)
            }
            products[i]['scopes'] = scopeAndPermissionList
          }
          this.setState({ productAndScopesList: products })
        })
        .catch(err => {
          console.log(err)
        })
    }

    changePermission = (e) => {
      let data = {}
      data['productId'] = e.target.dataset.productid
      data['scopeId'] = e.target.dataset.scopeid
      data['scopeName'] = e.target.dataset.scopename
      let newPermission = (e.target.dataset.checked === 'false')
      let products = this.state.productAndScopesList
      for (let i = 0; i < products.length; i++) {
        if (products[i]['productId'] === data['productId']) {
          for (let j = 0; j < products[i]['scopes'].length; j++) {
            if (products[i]['scopes'][j]['id'] === data['scopeId']) {
              products[i]['scopes'][j]['permission'] = newPermission
              break
            }
          }
        }
      }
      this.setState({ productAndScopesList: products })
    }

    renderProductAndScopes = () => {
      let result = []

      for (let i = 0; i < this.state.productAndScopesList.length; i++) {
        let product = this.state.productAndScopesList[i]
        result.push(
          <Fragment key={i}>
            <Card>
              <CardHeader>
                <Button className='accordion-manage-account-header-button'
                  data-event={i}
                  onClick={this.toggle}>
                  {product.productName}
                </Button>
              </CardHeader>
              <Collapse isOpen={this.state.accordionIndexOpen === i}>
                <CardBody>
                  <div className="form-inline" key={product.productId}>
                    <ProductAndScopes config={product} roleConfig={this.state.newRoleDetail}
                      changePermission={this.changePermission}></ProductAndScopes>
                  </div>
                </CardBody>
              </Collapse>
            </Card>
          </Fragment>
        )
      }

      return result
    }

    componentDidMount (): void {
      this.getProductAndScopes()
      this.setProgressBarVal('', 0)
    }

    componentDidUpdate (prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS): void {
      if (prevProps.isAddRoleOpen !== this.props.isAddRoleOpen) {
        this.setHasErrorTrue()
      }
    }

    isValidText = (value, errorKey) => {
      if (value && !value.match(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/)) {
        this.setState({ [errorKey]: false })
        return true
      }
      this.setState({ [errorKey]: true })
      return false
    }

    render () {
      let props = {
        role: this.props.role

      }

      return (

        <Modal isOpen={this.props.isAddRoleOpen}
          toggle={this.props.addRoleToggle}
          className='modal-dialog modal-lg'
        >
          <ModalHeader toggle={this.props.addRoleToggle} >New / Edit Role</ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <Label for="roleName"><strong>Role Name</strong></Label>
                <Input type="text" name="clientId" id="roleName" onChange={this.onChange} data-key='name' valid={!this.state.hasError_name} invalid={this.state.hasError_name} />
              </FormGroup>
              <FormGroup>
                <Label for="roleScopes"><strong> Description </strong></Label>
                <Input type="textarea" name="scopes" id="roleScopes" onChange={this.onChange} data-key='description' valid={!this.state.hasError_description} invalid={this.state.hasError_description}/>
              </FormGroup>

              <Card id="product-settings">
                <CardHeader>
                                      Products

                </CardHeader>
                <CardBody>
                  {/* {this.state.productAndScopesComponent} */}
                  {this.renderProductAndScopes()}
                </CardBody>
              </Card>
            </Form>
            { this.state.hasError &&
                         <h6 className="error"> All the red fields are mandatory and special characters not allowed </h6> }

            {(this.state.showProgressBar) ? <ProgressBar progressQuote={this.progressQuote} progressCompleted={this.progressCompleted} /> : null }

          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.createNewRole}>Create Role</Button>{' '}
            <Button color="secondary" onClick={this.props.addRoleToggle} >Cancel</Button>

          </ModalFooter>
          {/* {JSON.stringify(this.state.newRoleDetail)} */}
        </Modal>

      )
    }
}

export default EditRole
