// src/constants/app-types.js
const APP_SPLASH = 'APP_SPLASH'
const APP_EXPLORER = 'APP_EXPLORER'
const APP_INSTANT_CHECK = 'APP_INSTANT_CHECK'
const APP_MARKET_INTELLIGENCE = 'APP_MARKET_INTELLIGENCE'
const APP_PINPOINT = 'APP_PINPOINT'
const APP_PRIVACY_SHIELD = 'APP_PRIVACY_SHIELD'
const APP_REWARD_NOW = 'APP_REWARD_NOW'
const APP_MATCHBOX = 'APP_MATCHBOX'
const APP_VERIFICATION = 'APP_VERIFICATION'

export {
  APP_SPLASH,
  APP_EXPLORER,
  APP_INSTANT_CHECK,
  APP_MARKET_INTELLIGENCE,
  APP_PINPOINT,
  APP_PRIVACY_SHIELD,
  APP_REWARD_NOW,
  APP_MATCHBOX,
  APP_VERIFICATION
}
