import React, { Component } from 'react'
import { TabContent, TabPane, Nav, NavItem, NavLink, Button, Col, FormGroup, Input } from 'reactstrap'
import './styles.scss'
import config from 'common/config'
import Swal from 'sweetalert2'
import classnames from 'classnames'
import MeteringHistory from './MeteringHistory'
import axios from 'axios'
import DefaultHeader from '../../../containers/DefaultLayout/DefaultHeader'
import { AppHeader } from '@coreui/react'
import MeteringInfo from './MeteringInfo'

const axiosConfig = {
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
    Authorization: `Bearer ${sessionStorage.getItem('token')}`
  }
}

class Metering extends Component {
  constructor (props) {
    super(props)
    this.state = {
      activeTab: '1',
      inputTypePlaceHolder: 'Select Input Type',
      inputAction: '',
      inputAccount: '',
      inputUser: '',
      inputFromDate: null,
      inputToDate: null,
      elasticSearchResponse: null,
      accountDropdownOpen: false,
      accountList: [],
      userList: [],
      actionList: []
    }
    this.fetchAccountList = this.fetchAccountList.bind(this)
    this.fetchUserList = this.fetchUserList.bind(this)
    this.fetchActionList = this.fetchActionList.bind(this)
  }

  componentDidMount () {
    this.fetchAccountList()
  }

  toggleTab = tab => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      })
    }
  };

  onInputAccount = event => {
    if (event && event.target) {
      let value = event.target.value
      this.setState({
        inputAccount: value
      }, () => {
        this.fetchUserList(value)
      })
    }
  };

  loading = () => (
    <div className="animated fadeIn pt-1 text-center">Loading...</div>
  );

  onInputUser = event => {
    if (event && event.target) {
      let value = event.target.value
      this.setState({
        inputUser: value
      }, () => {
        this.fetchActionList(this.state.inputUser, this.state.inputAccount)
      })
    }
  };

  onInputFromDate = event => {
    if (event && event.target) {
      let value = event.target.value
      this.setState({
        inputFromDate: value
      })
    }
  };

  onInputToDate = event => {
    if (event && event.target) {
      let value = event.target.value
      this.setState({
        inputToDate: value
      })
    }
  }

  onInputAction = event => {
    if (event && event.target) {
      let value = event.target.value
      this.setState({
        inputAction: value
      })
    }
  };

  handleUpdate = (formData) => {
    this.setState({ didSubmitForm: false }, function () {
      this.onFormSubmit(formData)
    })
  }

  async fetchApiLog (action, fromDate, toDate, account, userId) {
    let meteringService
    if (('Metering' in config) && ('meteringService' in config.Metering)) {
      meteringService = config.Metering.meteringService
    }
    if (!meteringService) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Metering Service URL not specified in the config'
      })
      return
    }
    const jsonData = {
      action: action,
      fromDate: fromDate,
      toDate: toDate,
      accountId: account,
      userId: userId
    }

    const response = await new Promise((resolve, reject) => {
      axios
        .post(meteringService.url, jsonData, axiosConfig)
        .then(function (response) {
          if (response.status !== 200) {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Metering Service encountered Internal Server Error'
            })
            return null
          }
          return response.data
        })
        .then(responseJson => {
          resolve(responseJson)
        })
        .catch(error => {
          this.setState({
            elasticSearchResponse: {}
          })
          reject(error)
        })
    })
    if (response != null) {
      if (response.hits != null) {
        this.setState({
          elasticSearchResponse: response
        })
      } else if (response.hits != null && response.hits.hits.length === 0) {
        this.setState({
          elasticSearchResponse: {}
        })
      }
    } else if (response != null && response.error !== '') {
      this.setState({
        elasticSearchResponse: {}
      })
    } else {
      this.setState({
        elasticSearchResponse: {}
      })
    }
  }

  getUrl (baseUrl, pathToService) {
    baseUrl = baseUrl.endsWith('/') ? baseUrl.substring(0, baseUrl.length - 1) : (baseUrl)
    return baseUrl + pathToService
  }

  fetchAccountList () {
    let meteringService
    if (('Metering' in config) && ('meteringService' in config.Metering)) {
      meteringService = config.Metering.meteringService
    }
    if (!meteringService) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Metering Service URL not specified in the config'
      })
      return
    }

    let upsManager
    if (('Explorer' in config) && ('upsManager' in config.Explorer)) {
      upsManager = config.Explorer.upsManager
    }
    if (!upsManager) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'UPS Manager not specified in the explorer config'
      })
      return
    }

    let upsElasticSearch = this.getUrl(upsManager.url, upsManager.elasticSearch)
    let query = {
      'size': 0,
      'query': {
        'match_all': {}
      },
      'aggs': {
        'whatever_you_like_here': {
          'terms': {
            'field': 'account_id.keyword',
            'order': [
              {
                '_key': 'asc'
              }
            ]
          }
        }
      }
    }
    axios.post(upsElasticSearch + '/' + Metering.indexName + '/_search', query, axiosConfig).then((res) => {
      if (res) {
        let hitsArray = res.data.aggregations.whatever_you_like_here.buckets
        if (hitsArray.length > 0) {
          let itemList = hitsArray.map(item => item.key)
          this.setState({
            accountList: itemList
          })
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'No match found'
          })
        }
      }
    })
      .catch(error => {
        if (error.response) {
          console.log(error.response)
        }
      })
  }

  fetchUserList (accountName) {
    let meteringService
    if (('Metering' in config) && ('meteringService' in config.Metering)) {
      meteringService = config.Metering.meteringService
    }
    if (!meteringService) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Metering Service URL not specified in the config'
      })
      return
    }

    let upsManager
    if (('Explorer' in config) && ('upsManager' in config.Explorer)) {
      upsManager = config.Explorer.upsManager
    }
    if (!upsManager) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'UPS Manager not specified in the explorer config'
      })
      return
    }

    let upsElasticSearch = this.getUrl(upsManager.url, upsManager.elasticSearch)
    let query = {
      'size': 0,
      'query': {
        'match': {
          'account_id.keyword': accountName
        }
      },
      'aggs': {
        'whatever_you_like_here': {
          'terms': {
            'field': 'user_id.keyword',
            'order': [
              {
                '_key': 'asc'
              }
            ]
          }
        }
      }
    }

    axios.post(upsElasticSearch + '/' + Metering.indexName + '/_search', query, axiosConfig).then((res) => {
      // console.log(JSON.stringify(res))
      if (res) {
        let hitsArray = res.data.aggregations.whatever_you_like_here.buckets
        if (hitsArray.length > 0) {
          let itemList = hitsArray.map(item => item.key)
          this.setState({
            userList: itemList
          })
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'No match found'
          })
        }
      }
    })
      .catch(error => {
        if (error.response) {
          console.log(error.response)
        }
      })
  }

  fetchActionList (userName, accountName) {
    let meteringService
    if (('Metering' in config) && ('meteringService' in config.Metering)) {
      meteringService = config.Metering.meteringService
    }
    if (!meteringService) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Metering Service URL not specified in the config'
      })
      return
    }

    let upsManager
    if (('Explorer' in config) && ('upsManager' in config.Explorer)) {
      upsManager = config.Explorer.upsManager
    }
    if (!upsManager) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'UPS Manager not specified in the explorer config'
      })
      return
    }

    let upsElasticSearch = this.getUrl(upsManager.url, upsManager.elasticSearch)
    let query = {
      'size': 0,
      'query': {
        'bool': {
          'must': [
            {
              'match': {
                'user_id.keyword': userName
              }
            },
            {
              'match': {
                'account_id.keyword': accountName
              }
            }
          ]
        }
      },
      'aggs': {
        'whatever_you_like_here': {
          'terms': {
            'field': 'action.keyword',
            'order': [
              {
                '_key': 'asc'
              }
            ]
          }
        }
      }
    }

    axios.post(upsElasticSearch + '/' + Metering.indexName + '/_search', query, axiosConfig).then((res) => {
      // console.log(JSON.stringify(res))
      if (res) {
        let hitsArray = res.data.aggregations.whatever_you_like_here.buckets
        if (hitsArray.length > 0) {
          let itemList = hitsArray.map(item => item.key)
          this.setState({
            actionList: itemList
          })
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'No match found'
          })
        }
      }
    })
      .catch(error => {
        if (error.response) {
          console.log(error.response)
        }
      })
  }

  onFormSubmit = () => {
    let { inputAction, inputFromDate, inputToDate, inputAccount, inputUser } = this.state
    if (inputAccount === '') {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Enter account ID'
      })
      return
    }

    if (inputUser === '') {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Enter user ID'
      })
      return
    }

    if (inputFromDate == null) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Enter fromDate'
      })
      return
    }
    if (inputToDate == null) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Enter toDate'
      })
      return
    }

    if (inputAction === '') {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Enter Action'
      })
      return
    }

    this.fetchApiLog(inputAction, inputFromDate, inputToDate, inputAccount, inputUser)
  }

  toggle = () => this.setModal(!this.modal);

  accountToggle = () => {
    this.setState({
      accountDropdownOpen: !this.state.accountDropdownOpen
    })
  }

  render () {
    let { elasticSearchResponse, accountList, userList, actionList } = this.state

    console.log('accountList: ' + this.state.accountList)
    let optionAccountList = accountList.map(item =>
      <option key={item} value={item}>{item}</option>)

    console.log('userList: ' + this.state.userList)
    let optionUserList = userList.map(item =>
      <option key={item} value={item}>{item}</option>)

    console.log('actionList: ' + this.state.actionList)
    let optionActionList = actionList.map(item =>
      <option key={item} value={item}>{item}</option>)

    return (
      <div className="app">
        <AppHeader fixed>
          <DefaultHeader
            history={this.props.history}
            appDropDownDisable
          />
        </AppHeader>
        <div id="page-header">Metering</div>
        <div id="sub-header">
          <div className="row">
            <div className="col" id="sub-header-left">
              Metering
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-12">
              <div id="upload-section-score" style={{ textAlign: 'left' }}>
                <div className="row" style={{ paddingLeft: '20px' }}>
                  <div className="col">
                    <FormGroup row >
                      <label>Account ID</label>
                      <Input
                        type="select"
                        name="select_account"
                        id="select_account"
                        value={this.state.inputAccount}
                        onChange={this.onInputAccount}>
                        <option>Select Account</option>
                        {optionAccountList}
                      </Input>
                    </FormGroup>
                  </div>

                  <div className="col">
                    <FormGroup row>
                      <label>User ID</label>
                      <Input
                        type="select"
                        name="select_user"
                        id="select_user"
                        value={this.state.inputUser}
                        onChange={this.onInputUser}>
                        <option>Select User</option>
                        {optionUserList}
                      </Input>
                    </FormGroup>
                  </div>

                  <div className="col">
                    <FormGroup row>
                      <Col sm={10}>
                        <label>Action</label>
                        <Input
                          type="select"
                          name="select_action"
                          id="select_action"
                          value={this.state.inputaction}
                          onChange={this.onInputAction}>
                          <option>Select Action</option>
                          {optionActionList}
                        </Input>
                      </Col>
                    </FormGroup>
                  </div>
                </div>
                <div className="row" style={{ paddingLeft: '20px' }}>
                  <div className="col">
                    <FormGroup row>
                      <label>From Date</label>
                      <Input
                        type="date"
                        name="input_box"
                        id="input_value"
                        placeholder={'fromDate'}
                        value={this.state.inputType}
                        onChange={this.onInputFromDate}>
                      </Input>
                    </FormGroup>
                  </div>

                  <div className="col">
                    <FormGroup row>

                      <label>To Date</label>
                      <Input
                        type="date"
                        name="input_box"
                        id="input_value"
                        placeholder={'toDate'}
                        value={this.state.inputType}
                        onChange={this.onInputToDate}>
                      </Input>
                    </FormGroup>
                  </div>

                  <div className="col">
                    <Button
                      style={{ fontWeight: 'bold', marginTop: '30px' }}
                      onClick={this.onFormSubmit}
                    >
                      SUBMIT
                    </Button>
                  </div>
                </div>
              </div>
              <div id="score-section" className="no-padding">
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === '1' })}
                      onClick={() => { this.toggleTab('1') }}>
                      INFO
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === '2' })}
                      onClick={() => { this.toggleTab('2') }}>
                      HISTORY
                    </NavLink>
                  </NavItem>
                </Nav>

                <TabContent activeTab={this.state.activeTab}>
                  <TabPane tabId="1">
                    <MeteringInfo response={elasticSearchResponse} />
                  </TabPane>
                  <TabPane tabId="2">
                    <MeteringHistory response={elasticSearchResponse} />
                  </TabPane>
                </TabContent>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default Metering
