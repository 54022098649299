import React, { Component } from 'react'
import axios from 'axios'
import config from 'common/config'

import {
  Col,
  Card,
  CardBody,
  Table,
  Button,
  NavItem,
  Nav,
  NavLink,
  CardHeader,
  Row, Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap'
import toast from 'cogo-toast'

export default class Users extends Component {
  constructor (props) {
    super(props)

    this.state = {
      data: [],
      reload: true,
      userToDelete: null,
      warningModal: false
    }
  }

  handleAddUser = () => {
    this.props.history.push({
      pathname: '/user-info',
      state: { selectedUser: {} }
    })
  };

  handleDeleteUser = () => {
    const user = this.state.userToDelete ? this.state.userToDelete : null
    console.log('Deleting User ...', user)
    if (user && user.userId) {
      axios
        .delete(
          config.AccountPermissionManager.manageUsers.url + '/' + user.userId, {
            headers: {
              'Content-Type': 'application/json;charset=UTF-8',
              Authorization: `Bearer ${sessionStorage.getItem('token')}`
            }
          }
        )
        .then(response => {
            if(response.status === 200){
                toast.success('Successfully deleted')
                console.log(response)
                const filteredResult = this.state.data.filter((existingUser) => existingUser.userId !== user.userId)
                this.setState({ data: filteredResult, reload: true, userToDelete: null })
            }else{
                toast.error('Something went wrong')
            }
        })
        .catch(err => toast.error('Something went wrong') )
    } else {
      console.log(
        'could not delete user ...', user.userId)
    }
    console.log('after delete , state =', this.state)
  }

  toggleWarningModal = () => {
    this.setState({
      warningModal: !this.state.warningModal
    })
  }

  setUserToDelete = (user) => {
    this.setState({
      userToDelete: user
    })
  }

  componentDidMount () {
    axios
      .get(config.AccountPermissionManager.manageUsers.url, {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          Authorization: `Bearer ${sessionStorage.getItem('token')}`
        }
      })
      .then(response => {
        let filteredResult = []
        let usersData = response.data
        let count = 0
        for (let i = 0; i < usersData.length; i++) {
          if (usersData[i]['username'] !== 'sysadmin') {
            let temp = {}
            temp['id'] = ++count
            temp['userId'] = usersData[i]['id']
            temp['username'] = usersData[i]['username']
            temp['firstName'] = usersData[i]['firstName']
            temp['lastName'] = usersData[i]['lastName']
            temp['email'] = usersData[i]['email']
            filteredResult.push(temp)
          }
        }
        this.setState({ data: filteredResult, reload: false })
      })
      .catch(err => console.log(err))
  }

  render () {
    return (
      <div className='container-fluid'>
        <Card body>
          <CardBody>
            <CardHeader style={{ paddingRight: '20px', marginRight: '200px', paddingBottom: '3px', paddingTop: '3px' }}>
              <Row color="primary">
                <Col >
                  <h5 style={{ paddingTop: '5px' }}>
                    <i class="fa fa-align-justify"/>
                    {' '} Manage Users
                  </h5>
                </Col>
                <Col style={{ textAlign: 'right' }}>
                  <Button color="primary" onClick={this.handleAddUser} class='btn-primary'>
                    <i className="fa fa-plus-circle" aria-hidden="true"></i> Add
                    User
                  </Button>
                </Col>
              </Row>
            </CardHeader>
            <Row className='user-table' style ={{ paddingRight: '200px', marginLeft: '0px' }}>
              <Table size="sm" striped bordered hover>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Username</th>
                    <th>Email</th>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.data.map((item, idx) => {
                    return (
                      <tr key={idx + 1}>
                        <th scope="row">{idx + 1}</th>
                        <td>{item.username}</td>
                        <td>{item.email}</td>
                        <td>{item.firstName}</td>
                        <td>{item.lastName}</td>
                        <td>
                          <Nav>
                            <NavItem>
                              <NavLink
                                href="#/user-info"
                                onClick={() => {
                                  this.props.history.push({
                                    pathname: '/user-info',
                                    state: { selectedUser: item }
                                  })
                                }}
                              >
                                <i className="icon-note" title='Edit'></i>{' '}
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <div>
                                <NavLink
                                  onClick={() => {
                                    this.toggleWarningModal()
                                    this.setUserToDelete(item)
                                  }}

                                >
                                  <i className="icon-trash" title='Delete'></i>{' '}
                                  <Modal isOpen={this.state.warningModal}
                                    toggle={this.toggleWarningModal}
                                    autoFocus={false}
                                    size="md">
                                    <ModalHeader toggle={this.toggleWarningModalggle}><i class="fas fa-exclamation-triangle"></i> Warning! </ModalHeader>
                                    <ModalBody>
                                      {`Delete user ${this.state.userToDelete ? this.state.userToDelete.username : null} ?` }
                                    </ModalBody>
                                    <ModalFooter>
                                      <Button color="primary" onClick={() => {
                                        this.handleDeleteUser()
                                        this.toggleWarningModal()
                                      }}>Yes</Button>{' '}
                                      <Button color="secondary" onClick={this.toggleWarningModal}>Cancel</Button>
                                    </ModalFooter>
                                  </Modal>

                                </NavLink>
                              </div>
                            </NavItem>

                          </Nav>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
            </Row>

          </CardBody>
        </Card>
      </div>
    )
  }
}
