import React from 'react'
import AppComponent from '../AppComponent'

const User = React.lazy(() => import('./User'))
const POI = React.lazy(() => import('./Geomarker/POI'))
const Client = React.lazy(() => import('./Geomarker/Client'))
const Dashboard = React.lazy(() => import('./Dashboard'))
const Sormas = React.lazy(() => import('./Sormas/Sormas'))
const MovementMatrix = React.lazy(() => import('./PeopleMovement/Movement'))
const Density = React.lazy(() => import('./PeopleMovement/Density'))
const SpreadMap = React.lazy(() => import('./SpreadMap'))
const GeoIntelligence = React.lazy(() => import('./GeoIntelligence'))
const PolicyModel = React.lazy(() => import('./PolicyModel'))

class Pinpoint extends AppComponent {
  static appDescriptor = {
    name: 'Pinpoint',
    url: '/app/pinpoint',
    icon: 'icon-pin',
    // includeGeomarker: { parent: 'Geomarker' },
    nav: {
      items: [
        // {
        //   name: 'Dashboard',
        //   url: '/app/pinpoint/dashboard',
        //   icon: 'fas fa-tasks',
        //   permissions: ['pinpoint:trace:view', 'pinpoint:view']
        // },
        // {
        //   name: 'Tracing',
        //   url: '/app/pinpoint/tracing/exposure',
        //   icon: 'fas fa-street-view',
        //   permissions: ['pinpoint:trace:view', 'pinpoint:view']
        // },
        // {
        //   name: 'Covid-19 Spread',
        //   url: '/app/pinpoint/spread-map',
        //   icon: 'fas fa-chart-line',
        //   permissions: ['pinpoint:view']
        // },
        // {
        //   url: '/app/pinpoint/geomarker/poi',
        //   name: 'People Movement',
        //   icon: 'fas fa-location-arrow',
        //   permissions: ['pinpoint:view']
        // },
        {
          name: 'Geomarker',
          url: '/app/pinpoint/geomarker/custom',
          icon: 'fas fa-map-marker',
          permissions: ['pinpoint:view']
        }
      ]
    },
    routes: [
      { path: '/app/pinpoint', exact: true, name: 'Pinpoint', component: Client, permissions: ['pinpoint:trace:view', 'pinpoint:view'] },
      { path: '/app/pinpoint/location', name: 'Location', component: GeoIntelligence, permissions: ['pinpoint:view'] },
      // { path: '/app/pinpoint/geomarker/POI', name: 'POI', component: POI, permissions: ['pinpoint:view'] },
      { path: '/app/pinpoint/geomarker/Custom', name: 'Custom', component: Client, permissions: ['pinpoint:view'] },
      // { path: '/app/pinpoint/dashboard', name: 'Dashboard', component: Dashboard, permissions: ['pinpoint:view', 'pinpoint:trace:view'] },
      // { path: '/app/pinpoint/peoplemovement/movement metrics', name: 'Movement Metrics', component: MovementMatrix, permissions: ['pinpoint:view'] },
      // { path: '/app/pinpoint/peoplemovement/density', name: 'Density', component: Density, permissions: ['pinpoint:view'] },
      // { path: '/app/pinpoint/spread-map', name: 'Spread Map', component: SpreadMap, permissions: ['pinpoint:view'] },
      // { path: '/app/pinpoint/covid-spread/Spread Map', name: 'Spread Map', component: SpreadMap, permissions: ['pinpoint:view'] },
      // { path: '/app/pinpoint/covid-spread/Policy Model', name: 'Policy Model', component: PolicyModel }
    ]
  }
  render () {
    return <div>Entering Eureka Pinpoint ...</div>
  }
}

export default Pinpoint
