import React from 'react'
import { NavLink } from 'react-router-dom'
import { DropdownItem } from 'reactstrap'
import { AuthContextConsumer } from 'auth/AuthContext'
import roles from '../../auth/authRoles'
import config from 'common/config'

const adminLinksViewAllowed = user => {
  if (
    roles.powerUserRoles.some(powerRole => user.roles.includes(powerRole)) ||
    user.permissions.includes('user:access:manage')
  ) {
    return true
  }
  return false
}

const dashboardView = user => {
  return (
    adminLinksViewAllowed(user) || user.permissions.includes('dashboard:view')
  )
}

export default function AdminLinks() {
  return (
    <AuthContextConsumer>
      {authState => {
        const { user } = authState

        return (
          <React.Fragment>
            {adminLinksViewAllowed(user) ? (
              <NavLink to="/manage-products">
                <DropdownItem>
                  <i className="fa fa-users" aria-hidden="true"></i> Admin
                  Settings
                </DropdownItem>
              </NavLink>
            ) : (
              <></>
            )}
            
          </React.Fragment>
        )
      }}
    </AuthContextConsumer>
  )
}

AdminLinks.links = [
  {
    url: '#/manage-products',
    name: 'Services',
    icon: 'icon-drop',
  },
  {
    name: 'Users',
    url: '#/manage-users',
    icon: 'icon-drop',
  },
  {
    url: '#/manage-roles',
    name: 'Roles',
    icon: 'icon-drop',
  },
  {
    name: 'Accounts',
    url: '#/manage-account',
    icon: 'icon-drop',
  },
]
