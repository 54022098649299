import React from 'react'
import AppComponent from '../AppComponent'

import './scss/Explorer.css'

const Search = React.lazy(() => import('./Search/Search'))
const Queries = React.lazy(() => import('./Queries/Queries'))
const Lookalike = React.lazy(() => import('./Lookalike/Lookalike'))

class Explorer extends AppComponent {
  static appDescriptor = {
    name: 'Explorer',
    url: '/app/explorer',
    icon: 'icon-eye',
    aliases: ['Audience Explorer', 'Explorer'],
    nav: {
      items: [
        {
          name: 'Explore',
          url: '/app/explorer/search',
          icon: 'icon-drop',
          permissions: ['explorer:search-ui:view', 'explorer:all']
        },
        {
          name: 'Lookalike',
          url: '/app/explorer/lookalike',
          icon: 'icon-eye',
          permissions: ['explorer:lookalike-ui:view', 'explorer:all']
        },
        {
          name: 'Segments',
          url: '/app/explorer/queries',
          icon: 'icon-pencil',
          permissions: ['explorer:queries-ui:view', 'explorer:all']
        }
      ]
    },
    routes: [
      {
        path: '/app/explorer',
        exact: true,
        name: 'Explorer',
        component: Search,
        permissions: ['explorer:search-ui:view', 'explorer:all']
      },
      {
        path: '/app/explorer/search',
        name: 'Search',
        component: Search,
        permissions: ['explorer:search-ui:view', 'explorer:all']
      },
      {
        path: '/app/explorer/lookalike',
        name: 'Lookalike',
        component: Lookalike,
        permissions: ['explorer:lookalike-ui:view', 'explorer:all']
      },
      {
        path: '/app/explorer/queries',
        name: 'Queries',
        component: Queries,
        permissions: ['explorer:queries-ui:view', 'explorer:all']
      }
    ]
  };

  render () {
    return <div>Entering Eureka Explorer ...</div>
  }
}

export default Explorer
