import { Component } from 'react'

class AppComponent extends Component {
  nav = () => {
    return {
      items: []
    }
  }
}

export default AppComponent
