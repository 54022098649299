

import React, { Component } from 'react'
import { Bar } from 'react-chartjs-2'
import theme from '../Theme'

class BarChart extends Component {
  render () {
    function compare (a, b) {
      let comparison = 0
      if (a.doc_count > b.doc_count) {
        comparison = 1
      } else if (a.doc_count < b.doc_count) {
        comparison = -1
      }
      return comparison
    }

    function getIndex (key, bucketData) {
      for (let i = 0; i < bucketData.length; i++) {
        if (bucketData[i].key.toLowerCase() === key) {
          return i
        }
      }
      return -1
    }

    function reorderData (data) {
      let modifiedData = []
      let index = getIndex('gen z', bucketData)
      if (index !== -1) {
        modifiedData.push(data[index])
      }

      index = getIndex('millennials', bucketData)
      if (index !== -1) {
        modifiedData.push(data[index])
      }

      index = getIndex('gen x', bucketData)
      if (index !== -1) {
        modifiedData.push(data[index])
      }

      index = getIndex('baby boomers', bucketData)
      if (index !== -1) {
        modifiedData.push(data[index])
      }

      index = getIndex('traditionalists', bucketData)
      if (index !== -1) {
        modifiedData.push(data[index])
      }

      index = getIndex('unknown', bucketData)
      if (index !== -1) {
        modifiedData.push(data[index])
      }
      return modifiedData
    }
    let { aggregations, chartLabel, width, height, sort = false, nested = false } = this.props

    if (aggregations) {
      var bucketData
      if (nested) {
        if (aggregations.dataFieldValue.hasOwnProperty('dataFieldValue')) {
          bucketData = aggregations.dataFieldValue.dataFieldValue.buckets
        } else bucketData = []
      } else {
        bucketData = aggregations.dataFieldValue.buckets
      }

      if (bucketData.length !== 0) {
        if (sort === true) {
          bucketData.sort(compare)
        }
        if (chartLabel === 'Age Bucket') {
          bucketData = reorderData(bucketData)
        }
        let labelList = []
        let dataList = []
        for (var i = 0; i < bucketData.length; i++) {
          labelList.push(bucketData[i].key)
          dataList.push(bucketData[i].doc_count)
        }

        let chartJsData = {
          labels: labelList,
          datasets: [{
            data: dataList,
            label: chartLabel.chartLabel,
            borderWidth: 1,
            width: width,
            height: height,
            backgroundColor: theme.chartColors[0],
            hoverBackgroundColor: theme.chartColors[0],
            hideInLegendAndTooltip: false
          }]
        }

        return (
          <div>
            <Bar data={chartJsData}
              width={100}
              height={50}
              options={{
                maintainAspectRatio: true,
                legend: {
                  display: false
                }
              }} />
          </div>
        )
      } else return null
    }
    return null
  }
}

export default BarChart
