const theme = {
  typography: {
    fontFamily: '-apple-system, BlinkMacSystemFont, "verdana", "Roboto", "Noto Sans", "Ubuntu", "Droid Sans", "Helvetica Neue", sans-serif',
    fontSize: '16px'
  },
  colors: {
    textColor: '#000',
    backgroundColor: '#fff', // datepicker background color and filters colors
    primaryTextColor: '#fff', // checkboxes tick colors
    primaryColor: '#000', // if you hover over list elements; dates in the datepicker
    titleColor: '#000',
    alertColor: '#fff',
    borderColor: '#000' // border in the checkboxes in the left hand panel
  },
  chartColors: ['#96DFEE',
    '#FCB581',
    '#DC5465',
    '#5EC9C2',
    '#C088CB',
    '#C95E87',
    '#96DFEE',
    '#FCB581',
    '#DC5465',
    '#5EC9C2',
    '#C088CB',
    '#C95E87']
}

export default theme
