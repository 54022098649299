import React, { Component } from 'react'

import {
  Button, Card,
  Form,
  FormGroup,
  Input,
  Label, Modal,
  ModalBody, ModalFooter,
  ModalHeader } from 'reactstrap'
import CardHeader from 'reactstrap/es/CardHeader'
import CardBody from 'reactstrap/es/CardBody'

import Collapse from 'reactstrap/es/Collapse'
import axios from 'axios'
import '../styles.scss'
import config from 'common/config'
import toast from 'cogo-toast'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
// import ProgressBar from './ProgressBar'

class EditAccount extends Component {
  constructor (props) {
    super(props)
    this.state = {
      accordionIndexOpen: 1,
      accordionIndexOpenUserGroup: 1,
      newAccountDetail: { },

      productsList: [],
      hasError_accountName: true,
      hasError: false

    }
  }

    toggle = (e) => {
      let accordionIndex = Number(e.target.dataset.event)
      console.log(' accordion Index is : ' + accordionIndex)
      this.setState({
        accordionIndexOpen: accordionIndex
      })
    }

     toggleAddUserGroup = (e) => {
       let accordionIndex = Number(e.target.dataset.event)
       console.log(' accordion Index is : ' + accordionIndex)
       this.setState({
         accordionIndexOpenUserGroup: accordionIndex
       })
     }

    onChange = (e) => {
      // let stateVar = e.target.dataset.stateVar
      let key = e.target.dataset.key
      let value = e.target.value.trim()

      if (!this.isValidText(value, 'hasError_' + key)) {
        return null
      }
      this.setState(prevState => ({
        ...prevState,
        newAccountDetail: {
          ...prevState.newAccountDetail,
          [key]: value
        }
      }))
      // console.log(' new json is : ', JSON.stringify(data))
    }

    createNewAccount = () => {
      if (this.state.hasError_accountName) {
        this.setState({ hasError: true })
        return null
      }
      this.setState({ hasError: false })
      let newAccount = this.state.newAccountDetail
      // console.log(" inside create new account data is : ", JSON.stringify(data))
      let prodSubsList = []
      let products = this.state.productsList
      for (let i = 0; i < products.length; i++) {
        if (products[i].checked === true) {
          let newProdSubs = {}
          newProdSubs['name'] = products[i]['name']
          newProdSubs['id'] = products[i]['id']
          prodSubsList.push(JSON.stringify(newProdSubs))
        }
      }
      newAccount['prodSubsList'] = prodSubsList
      // console.log(" before sending new Account is : ", newAccount)
      this.setState({ newAccountDetail: newAccount }, () => {
        this.sendCreateRequest()
      })
    }

    sendCreateRequest = () => {
      let newAccount = this.state.newAccountDetail

      console.log(' new Account is : ', newAccount)
      axios
        .post(config.AccountPermissionManager.manageAccounts.url, newAccount, {
          headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${sessionStorage.getItem('token')}`
          }
        })
        .then(resp => {
          if (resp.status === 200) {
            toast.success(' Successfully created ')
            this.props.getAccountsList()
            this.props.addAccountToggle()
            this.getProductList()
            this.setHasErrorTrue()
          } else {
            toast.error(' Something went wrong ')
          }
        })
        .catch(err => toast.error('Something went wrong'))
    }

    setHasErrorTrue = () => {
      this.setState({ hasError_accountName: true })
    }

    getProductList = () => {
      axios
        .get(config.AccountPermissionManager.manageProducts.url, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`
          }
        })
        .then(resp => {
          let filteredData = resp.data.map((prod, index) => {
            return {
              'sNo': index + 1,
              'name': prod.name,
              'id': prod.id,
              'checked': false
            }
          })
          this.setState({ productsList: filteredData })
        })
        .catch(err => console.log(err))
    }

    onClick = (e) => {
      let index = e.target.dataset.id
      let newProductsList = this.state.productsList
      newProductsList[index]['checked'] = !this.state.productsList[index]['checked']
      this.setState({ productsList: newProductsList })
    }

    actionsFormatter = (cell, row) => {
      return (
        <div align='center' >
          <Input type='checkbox' key = {row.id} name={row.name} data-id = {row.sNo - 1} value={this.state.productsList[row.sNo - 1]['checked']} onChange={this.onClick} ></Input>
          {/* <Input type='checkbox' value={false} > </Input> */}
          {/*    <text> hello </text> */}
        </div>
      )
    }

    componentDidMount (): void {
      this.getProductList()
    }

    componentDidUpdate (prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS): void {
      if (prevProps !== this.props) {
        this.setState({ accordionIndexOpen: 1 })
      }
    }

    isValidText = (value, errorKey) => {
      if (value && !value.match(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/)) {
        this.setState({ [errorKey]: false })
        return true
      }
      this.setState({ [errorKey]: true })
      return false
    }

    render () {
      return (
        <div className="app">

          <Modal isOpen={this.props.isAddAccountOpen}
            toggle={this.props.addAccountToggle}
            className='modal-dialog modal-lg add-account-modal'
          >
            <ModalHeader toggle={this.props.addAccountToggle} >New Account</ModalHeader>
            <ModalBody>

              <Card key="1">
                <CardHeader>

                  <Button className='accordion-manage-account-header-button'
                    data-event={1}
                    onClick={this.toggle}>
                                     Basic Info
                  </Button>
                </CardHeader>
                <Collapse isOpen={this.state.accordionIndexOpen === 1}>
                  <CardBody>
                    <Form>
                      <FormGroup>
                        <Label for="accountName"><strong>Account Name</strong></Label>
                        <Input type="text" name="clientId" id="accountName" onChange={this.onChange} data-key='accountName' valid={!this.state.hasError_accountName} invalid={this.state.hasError_accountName}/>
                      </FormGroup>
                    </Form>

                  </CardBody>
                </Collapse>
              </Card>

              <Card key="2">
                <CardHeader>

                  <Button className='accordion-manage-account-header-button'
                    data-event={2}
                    onClick={this.toggle}>

                                    Service Subscription
                  </Button>
                </CardHeader>
                <Collapse isOpen={this.state.accordionIndexOpen === 2}>
                  <CardBody>
                    <BootstrapTable
                      trClassName='manage-account-table-row'

                      data={this.state.productsList}
                      striped
                      search
                      // exportCSV
                      hover
                      version='4'
                      ignoreSinglePage={true}
                      headerContainerClass='manage-account-table-header'
                    >
                      <TableHeaderColumn isKey dataField="sNo"
                        thStyle={{ width: '5%', color: 'white' }}
                        tdStyle={{ width: '5%' }}
                      >
                                      #
                      </TableHeaderColumn>
                      <TableHeaderColumn dataField="name" dataSort
                        thStyle={{ color: 'white' }}
                      >
                                   Services
                      </TableHeaderColumn>

                      <TableHeaderColumn dataFormat={this.actionsFormatter}
                        thStyle={{ width: '20%', color: 'white' }}
                        tdStyle={{ width: '20%', verticalAlign: 'top' }}
                      >
                                      Subscribe

                      </TableHeaderColumn>
                    </BootstrapTable>
                  </CardBody>
                </Collapse>
              </Card>

              { this.state.hasError &&
                                                     <h6 className="error"> You are not allowed to use special characters in Red fields </h6> }
            </ModalBody>

            <ModalFooter>
              <Button color="primary" onClick={this.createNewAccount}>Create Account</Button>{' '}
              <Button color="secondary" onClick={this.props.addAccountToggle} >Cancel</Button>
            </ModalFooter>
          </Modal>

        </div>

      )
    }
}

export default EditAccount
