import React, { Component, Fragment } from 'react'
import DefaultHeader from '../../../containers/DefaultLayout/DefaultHeader'

import {
  Button,
  Card,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Label,
  Row,
  CardBody
} from 'reactstrap'
import { AppHeader } from '@coreui/react'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import UncontrolledTooltip from 'reactstrap/lib/UncontrolledTooltip'
import axios from 'axios'
import config from '../../../common/config'
import './styles.scss'
import EditRoles from './EditRoles'
import RemoveAlert from '../Products/RemoveAlert'
import AddRole from './AddRole'
import toast from 'cogo-toast'
class Roles extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isEditRoleOpen: false,
      roleDropdownOpen: false,
      isAddRoleOpen: false,
      rolePrivilegeList: [],
      roleDefinitionList: [],
      productAndScopeListForARole: [],
      editRoleModalData: null,
      dummy: false,
      deleteItemDetail: {}
    }
  }

    addRoleToggle = () => {
      this.setState({
        isAddRoleOpen: !this.state.isAddRoleOpen
      })
    }

    toggle = () => {
      this.setState({
        isEditRoleOpen: !this.state.isEditRoleOpen
      })
    }
    dummyToggle = () => {
      this.setState({
        dummy: !this.state.dummy
      })
    }
    roleToggle = () => {
      this.setState({
        roleDropdownOpen: !this.state.roleDropdownOpen
      })
    }

    setRoleAndPermission = (isToggleOn, selectedRow) => {
      let row = selectedRow
      axios
        .get(config.AccountPermissionManager.manageProducts.url, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`
          }
        })
        .then(response => {
          // alert("response data is: ", JSON.stringify(response))
          let filteredData = {}
          let products = response.data
          let productAndScopesList = []
          for (let i = 0; i < products.length; i++) {
            let productAndScopes = {}
            productAndScopes['productName'] = products[i].name
            productAndScopes['productId'] = products[i].id
            let scopes = products[i]['scopes']
            let scopeAndPermissionList = []
            for (let j = 0; j < scopes.length; j++) {
              let scopeAndPermission = {}
              scopeAndPermission['id'] = scopes[j].id
              scopeAndPermission['name'] = scopes[j].name
              scopeAndPermission['permission'] = false
              if (row['privileges'] != null || row['privileges'] !== undefined) {
                if (row['privileges'][products[i].name] !== undefined) {
                  if (row['privileges'][products[i].name]['associatedScopes'] != null) {
                    let associatedScopes = row['privileges'][products[i].name]['associatedScopes']
                    for (let k = 0; k < associatedScopes.length; k++) {
                      if (associatedScopes[k].scopeName.trim() === scopes[j].name.trim()) {
                        scopeAndPermission['permission'] = true
                      }
                    }
                  }
                }
              }
              scopeAndPermissionList.push(scopeAndPermission)
            }
            // console.log('scope and permission list : ', JSON.stringify(scopeAndPermissionList))
            productAndScopes['scopes'] = scopeAndPermissionList
            productAndScopesList.push(productAndScopes)
          }
          filteredData['productAndScopes'] = productAndScopesList
          filteredData['roleName'] = row.name
          filteredData['roleId'] = row.id
          filteredData['sNo'] = row.sNo
          filteredData['roleDescription'] = row.description
          filteredData['privileges'] = row['privileges']
          // console.log(' filtered Data in permission is : ', JSON.stringify(filteredData))
          if (isToggleOn) {
            this.setState({ editRoleModalData: filteredData }, () => {
              this.getRoleAndPrivilegesData()
            })
          } else {
            this.setState({ editRoleModalData: filteredData }, () => {
              this.getRoleAndPrivilegesData(); this.toggle()
            })
          }
        })
        .catch(err => console.log(err))
    }

    modifyRoleAndPermission = (change, privileges) => {
      axios
        .get(config.AccountPermissionManager.manageRoles.url + '/privileges', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`
          }
        })
        .then(resp => {
          // let filteredResult = []
          let roleAndPrivilege = resp.data
          let wantedRole = null
          for (let roleKey of Object.keys(roleAndPrivilege)) {
            let roleDefinition = JSON.parse(roleKey)
            if (roleDefinition['roleId'] === privileges['roleId']) {
              wantedRole = roleAndPrivilege[roleKey]
              break
            }
          }
          let selectedRow = {}
          selectedRow['id'] = privileges['roleId']
          selectedRow['name'] = privileges['roleName']
          selectedRow['privileges'] = wantedRole
          this.setRoleAndPermission(true, selectedRow)
          // this.setState({ rolePrivilegeList : filteredResult}, () => { console.log(" in manage roles table .. ", this.state.rolePrivilegeList)})
        })
        .catch(err => console.log(err))
    }

    getProductAndScopeListForARole = (e) => {
      let row = JSON.parse(e.target.dataset.row)
      let roleName = row.name
      row['privileges'] = row.privileges

      this.setRoleAndPermission(false, row)
    }

    getRoleDetail = (e) => {
      // console.log(" ")
      let privileges = JSON.parse(e.target.dataset.row)
      // let privileges = this.state.roleDefinitionList[listIndex]
      this.setState({ editRoleModalData: privileges }, this.toggle)
    }

    privilegesFormatter = (cell, row) => {
      let rowPrivileges = row.privileges
      let result = []
      let temp = ''
      for (let product of Object.keys(rowPrivileges)) {
        let privileges = rowPrivileges[product]
        // result.push('<B>' + product + '</B>')

        if (privileges['associatedScopes'] !== null) {
          temp += ('<B>' + product + '</B>')
          privileges['associatedScopes'].map(associatedScope => {
            temp += ('<i>' + associatedScope.scopeName + ' </i>')
          })
        }
      }
      result.push(temp)
      return result
    }

    deleteRole = (deleteItemDetail) => {
      axios
        .delete(config.AccountPermissionManager.manageRoles.url + '/' + deleteItemDetail.deleteItemName, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`
          }
        })

        .then((resp) => {
          if (resp.status === 200) {
            toast.success('Successfully deleted')
            this.getRoleAndPrivilegesData()
            this.onDismiss()
          } else {
            toast.error('Something went wrong')
          }
        })
        .catch(err => toast.error('Something went wrong'))
    }
    onDismiss = () => {
      this.setState({ visible: !this.state.visible })
    }

    setDeleteItemDetail = (e) => {
      let deleteItemDetail = {}
      deleteItemDetail['deleteItemName'] = e.target.dataset.name
      deleteItemDetail['roleName'] = e.target.dataset.id
      this.setState({ deleteItemDetail: deleteItemDetail })
    }
    actionsFormatter = (cell, row) => {
      return (
        <Fragment>
         &nbsp;&nbsp;&nbsp; <i className='icon-note icon-cursor' id={`edit-${row.id}`} data-id={row.id} data-row={JSON.stringify(row)} onClick={this.getProductAndScopeListForARole} > </i> &nbsp;&nbsp;&nbsp;&nbsp;

          <UncontrolledTooltip placement='bottom' target={`edit-${row.id}`} > Edit </UncontrolledTooltip>

          <i className='icon-trash icon-cursor' id={`remove-${row.id}`} data-id={row.id} data-name={row.name} onClick={(e) => { this.onDismiss(); this.setDeleteItemDetail(e) } }></i>
          <UncontrolledTooltip placement='bottom' target={`remove-${row.id}`} > Remove</UncontrolledTooltip>
        </Fragment>
      )
    }

    getRoleAndPrivilegesData = () => {
      axios
        .get(config.AccountPermissionManager.manageRoles.url + '/privileges', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`
          }
        })
        .then(resp => {
          let filteredResult = []
          let roleAndPrivilege = resp.data
          let index = 1
          for (let roleKey of Object.keys(roleAndPrivilege)) {
            let roleDefinition = JSON.parse(roleKey)
            let role = {}
            role['name'] = roleDefinition['roleName']
            role['sNo'] = index++
            role['id'] = roleDefinition['roleId']
            role['description'] = roleDefinition['roleDescription']
            role['privileges'] = roleAndPrivilege[roleKey]
            filteredResult.push(role)
          }
          this.setState({ rolePrivilegeList: filteredResult })
        })
        .catch(err => console.log(err))
    }

    componentDidMount (): void {
      // this.getRoleDefinitionList()
      // this.getRolePrivilegeList()
      this.getRoleAndPrivilegesData()
    }

    render () {
      const { visible, deleteItemDetail } = this.state
      return (
        <div className='app'>

          <div className=" align-items-center manage-role-container ">
            {/* <div className='manage-role-container'> */}
            <div className='manage-role-div'>
              <Card>
                <CardHeader>
                  <Row>
                    <Col><h4 style={{ paddingTop: '5px' }}>Manage Role</h4></Col>
                    <Col style={{ textAlign: 'right' }}><Button color="primary" onClick={this.addRoleToggle}><i
                      className="fa fa-plus-circle" aria-hidden="true"></i> Add Role</Button></Col>
                  </Row>

                </CardHeader>
                <CardBody>
                  <BootstrapTable
                    trClassName='manage-role-table-row'
                    data={this.state.rolePrivilegeList}
                    striped
                    search
                    // exportCSV
                    hover
                    version='4'
                    pagination
                    ignoreSinglePage
                    headerContainerClass='manage-role-table-header'
                  >
                    <TableHeaderColumn isKey dataField="sNo"
                      thStyle={{ width: '5%', color: 'white' }}
                      tdStyle={{ width: '5%' }}
                    >
                          #
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField="name" dataSort
                      thStyle={{ width: '15%', color: 'white' }}
                      tdStyle={{ width: '15%' }}
                    >
                        Role Name
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField="description"
                      thStyle={{ width: '20%', color: 'white' }}
                      tdStyle={{ width: '20%', whiteSpace: 'normal' }}
                    >
                        Description
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField="privileges"
                      thStyle={{ color: 'white' }}
                      tdStyle={{ whiteSpace: 'normal' }}
                      dataFormat={this.privilegesFormatter}
                    >
                                              Privileges
                    </TableHeaderColumn>
                    <TableHeaderColumn dataFormat={this.actionsFormatter}
                      thStyle={{ width: '15%', color: 'white' }}
                      tdStyle={{ width: '15%' }}
                    >
                          Actions
                    </TableHeaderColumn>
                  </BootstrapTable>
                </CardBody>
              </Card>
            </div>
          </div>
          <div>
            <EditRoles
              editRoleToggle={this.toggle}
              isEditRoleOpen={this.state.isEditRoleOpen}
              getProductAndScopeListForARole={this.getProductAndScopeListForARole}
              getRoleAndPrivilegesData = {this.getRoleAndPrivilegesData}
              role={this.state.editRoleModalData}
              modifyRoleAndPermission={this.modifyRoleAndPermission}
            ></EditRoles>

            <AddRole
              isAddRoleOpen ={this.state.isAddRoleOpen}
              addRoleToggle={this.addRoleToggle}
              getRoleAndPrivilegesData = {this.getRoleAndPrivilegesData}
            ></AddRole>
            <RemoveAlert
              visible={visible}
              toggle={this.onDismiss}
              deleteItemDetail={deleteItemDetail}
              deleteFunction={this.deleteRole}
            ></RemoveAlert>
          </div>

          {/* </div> */}
        </div>
      )
    }
}

export default Roles
