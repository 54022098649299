import React, { Component } from 'react'
import Keycloak from 'keycloak-js'
import config from 'common/config'

import axios from 'axios'
const Context = React.createContext()

// const allApps = [
//   'Matchbox',
//   'Audience Explorer',
//   'Credit Score',
//   'Location Verification',
//   'Pinpoint',
//   'Graph',
//   'Reward Now',
//   'Market Intelligence',
//   'Insurance Score',
//   'UserAccessManager'
// ]
const allApps = [
  'Audience Explorer',
  'Credit Score',
  'Pinpoint',
  'Pinpoint DOOH',
  'DashboardService',
  'LVODService'
]

export class AuthContextProvider extends Component {
  constructor(props) {
    super(props)
    this.state = {
      user: {
        appsAllowed: [],
        permissions: [],
        roles: [],
        name: 'Guest',
        email: null,
        accounts: []
      },
      isAuthenticated: false,
      token: sessionStorage.getItem('token'),
      keycloak: null,
      loggedInUser: ''
    }
  }

  populatePermissions = keycloakInstance => {
    // using auth server's token endpoind to get permissions for each service/app
    const tokenEndpoint =
      keycloakInstance.authServerUrl +
      '/realms/' +
      keycloakInstance.realm +
      '/protocol/openid-connect/token'
    let permissions = []
    const axiosConfig = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Bearer ${keycloakInstance.token}`
      }
    }
    allApps.forEach(app => {
      const params = new URLSearchParams()
      console.log('app -> ', app)
      params.append('grant_type', 'urn:ietf:params:oauth:grant-type:uma-ticket')
      params.append('audience', app)
      params.append('response_mode', 'permissions')
      axios
        .post(tokenEndpoint, params, axiosConfig)
        .then(response => {
          // console.log('response -> ', response)
          response.data.forEach(obj => {
            if (obj.scopes) {
              permissions.push(...obj.scopes)
            }
          })
          let { user } = this.state
          // if (user != null && user.hasOwnProperty(permissions) && user.permissions.length > 0) {
          if (user != null) {
            this.setState({
              user: {
                ...user,
                permissions: [...permissions]
              }
            })

            // console.log('permission -> ', permissions)
            sessionStorage.setItem(
              'userPermissions',
              JSON.stringify([...permissions, ...user.roles])
            )
          }
        })
        .catch(err => console.log(err))
    })
  }

  componentDidMount() {
    // const keycloakInstance = new Keycloak('./keycloak.json')
    const keycloakInstance = new Keycloak(config.Auth.Keycloak)
    //  keycloakInstance.success(() => this.populatePermissions(keycloakInstance)).error(() => { console.log('FAILURE...') })

    keycloakInstance
      .init({ onLoad: 'login-required', checkLoginIframe: false }, () => {})
      .success(() => {
        const productAccess = keycloakInstance.tokenParsed.product_access
          ? keycloakInstance.tokenParsed.product_access
          : []
        // console.log(' token parsed is : ', productAccess)
        let appsAllowed = []

        if (productAccess.length > 0) {
          appsAllowed = productAccess.map(
            productItem => JSON.parse(productItem).name
          )
        }

        let authenticatedUser = {
          appsAllowed: [...appsAllowed],
          roles: keycloakInstance.tokenParsed.realm_access
            ? [...keycloakInstance.tokenParsed.realm_access.roles]
            : [],
          accounts: keycloakInstance.tokenParsed.groups
            ? [
                ...keycloakInstance.tokenParsed.groups.map(
                  group => group.split('/')[1]
                )
              ]
            : [],
          name: keycloakInstance.tokenParsed.name,
          userId: keycloakInstance.tokenParsed.sub,
          email: keycloakInstance.tokenParsed.email,
          username: keycloakInstance.tokenParsed.preferred_username,
          firstName: keycloakInstance.tokenParsed.given_name,
          lastName: keycloakInstance.tokenParsed.family_name
        }

        this.setState({
          user: {
            ...authenticatedUser,
            permissions: []
          },
          isAuthenticated: true,
          keycloak: keycloakInstance
        })

        // console.log('logged in user =', JSON.stringify(this.state.user))
        sessionStorage.setItem('token', keycloakInstance.token)
        sessionStorage.setItem(
          'loggedInUser',
          keycloakInstance.tokenParsed.preferred_username
        )
        this.populatePermissions(keycloakInstance)
      })

    keycloakInstance.onAuthLogout = () => {
      console.log("[debug] -> Logout ")
      sessionStorage.clear()
      // console.log('on logout....')
      if (this.props.history) {
        this.props.history.push('/login')
      }
    }

    keycloakInstance.onTokenExpired = () => {
      // console.log('token has expired...', keycloakInstance.token)
      keycloakInstance
        .updateToken(60)
        .success(() => {
          sessionStorage.setItem('token', keycloakInstance.token)
          // console.log('successfully refreshed token in session, new token =', sessionStorage.getItem('token'))
        })
        .error(() => {
          alert('Failed to refresh the token, or the session has expired')
        })
    }
  }

  render() {
    if (this.state.isAuthenticated) {
      return (
        <Context.Provider value={this.state}>
          {this.props.children}
        </Context.Provider>
      )
    }
    return <Context.Provider value={this.state}></Context.Provider>
  }
}


export const useAuth = () => React.useContext(Context)
export const AuthContextConsumer = Context.Consumer
export const AuthContext = Context
