import React, { Component, Fragment } from 'react'
import DefaultHeader from '../../../containers/DefaultLayout/DefaultHeader'

import {
  Button,
  Card,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Label,
  Row,
  CardBody, ModalFooter,
  Alert, Modal
} from 'reactstrap'
import { AppHeader } from '@coreui/react'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import axios from 'axios'
import config from 'common/config'
import './styles.scss'
import AddProduct from './AddProduct/AddProduct'
import EditProduct from './EditProduct/EditProduct'
import ModalHeader from 'reactstrap/es/ModalHeader'
import { ModalBody } from 'react-bootstrap'

class RemoveAlert extends Component {
  constructor (props) {
    super(props)
  }

  render () {
    return (
      <div>
        <Modal isOpen={this.props.visible} toggle={this.props.toggle}>
          <ModalHeader toggle={this.props.toggle}> <h4> <button className='btn btn-warning btn-block warning-button'> Warning!! </button> </h4> </ModalHeader>
          <ModalBody>
            <> Are you sure you want to delete {this.props.deleteItemDetail.deleteItemName} ? </>

          </ModalBody>
          <ModalFooter>
            <p className="mb-0">
              <Button color="primary" onClick={this.props.toggle}>Cancel </Button>{' '}
              <Button color="secondary" onClick={() => this.props.deleteFunction(this.props.deleteItemDetail)} > Remove </Button>
            </p>
          </ModalFooter>
        </Modal>
      </div>
    )
  }
}

export default RemoveAlert
