import React, {Component, Fragment} from 'react'
import './styles.scss'
import {
    Badge,
    Button, Card, CardBody, CardFooter, CardHeader,
    Col, Container, CustomInput, Dropdown, DropdownItem, DropdownMenu, DropdownToggle,
    Form,
    FormGroup,
    FormText,
    Input,
    Label, Modal,
    ModalBody, ModalFooter,
    ModalHeader, Nav, NavItem,
    Row,
    Table
} from 'reactstrap'

import Collapse from 'reactstrap/es/Collapse'
import {AppHeader, AppNavbarBrand, AppSidebarToggler} from "@coreui/react";
import logo from "../../../assets/img/brand/eureka_logo.png";
import sygnet from "../../../assets/img/brand/eureka-brand.png";
import {NavLink} from "react-router-dom";
import config from '../../../common/config'
import axios from 'axios'
import {BootstrapTable, TableHeaderColumn} from "react-bootstrap-table";


class ProductAndScopes extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render(){
        let props = this.props
        return(


                                props.config.scopes.map((scope,index2) => {
                                  return (
                                      <div key={scope.id}>
                                        <FormGroup >
                                          <Label for={ 'scope-' + scope.id} > {scope.name}</Label><br/> &nbsp;
                                          <CustomInput type='switch' id={'scope-' + scope.id} checked={scope.permission}
                                          data-productid={props.config.productId}
                                                       data-scopeid={scope.id}
                                                       data-scopename={scope.name}
                                                       onClick={props.changePermission}
                                                       data-rolename={props.roleConfig.roleName}
                                                       data-checked={scope.permission}
                                          ></CustomInput>
                                        </FormGroup>
                                      </div>
                                  )
                                })


        )
    }
}
export default ProductAndScopes;
