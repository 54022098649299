import { getCurrentDate } from 'views/App/Pinpoint/utils'
import config from 'common/config'

const useDateMinusOne = config.PinPoint.useDAUMinus

const datePeriodEnd = config.PinPoint.useCurrentDate ? getCurrentDate(useDateMinusOne) : config.PinPoint.dateProfile
const datePeriodStart = config.PinPoint.dateProfileStart

const datePeriodDisease = config.PinPoint.useCurrentDate ? getCurrentDate(useDateMinusOne) : config.PinPoint.datePeriodDisease

export const PINPOINT_CONSTANT = {
  dateProfileStart: datePeriodStart,
  datePeriod: datePeriodEnd,
  datePeriodDisease: datePeriodDisease,
  datePeriodDiseaseStart: config.PinPoint.datePeriodDiseaseStart,
  datePeriodPOI: config.PinPoint.datePeriodPOI,
  defaultResolution: config.PinPoint.defaultResolution,

  useUserExposure: config.PinPoint.useUserExposure,
  useFeatureEmiase: config.PinPoint.useFeatureEmiasi,
  cacheDistrict: 'district',
  cacheSubdistrictOrVillage: 'subdistrictOrVillage',
  cacheResolution: 'resolution',
  cachePoiCategory: 'poiCategory',
  cacheDate: 'lastSelectionDate'
}

export function destroyPinpointSession () {
  sessionStorage.removeItem(PINPOINT_CONSTANT.cacheDistrict)
  sessionStorage.removeItem(PINPOINT_CONSTANT.cachePoiCategory)
  sessionStorage.removeItem(PINPOINT_CONSTANT.cacheResolution)
  sessionStorage.removeItem(PINPOINT_CONSTANT.cacheSubdistrictOrVillage)
  sessionStorage.removeItem(PINPOINT_CONSTANT.cacheDate)
}

export function destroyPinpointLocalStorage (){
  let arr = []; // Array to hold the keys
  // Iterate over localStorage and insert the keys that meet the condition into arr
  for (var i = 0; i < localStorage.length; i++){
    if (localStorage.key(i).substring(0,3) == 'PP_') {
        arr.push(localStorage.key(i));
    }
  }

  // Iterate over arr and remove the items by key
  for (var i = 0; i < arr.length; i++) {
    localStorage.removeItem(arr[i]);
  }
}
