import Splash from '../../views/App/Splash'
import Explorer from '../../views/App/Explorer'
import InstantCheck from '../../views/App/InstantCheck'
import MarketIntelligence from '../../views/App/MarketIntelligence'
import Pinpoint from '../../views/App/Pinpoint'
import PrivacyShield from '../../views/App/PrivacyShield'
import Matchbox from '../../views/App/Matchbox'
import RewardNow from '../../views/App/RewardNow'
import Verification from '../../views/App/Verification'
let Apps = [
  Splash.appDescriptor,
  Explorer.appDescriptor,
  InstantCheck.appDescriptor,
  MarketIntelligence.appDescriptor,
  Pinpoint.appDescriptor,
  PrivacyShield.appDescriptor,
  RewardNow.appDescriptor,
  Matchbox.appDescriptor,
  Verification.appDescriptor
]

export default Apps
