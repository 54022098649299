import React, { createContext, useState, useContext, useEffect } from 'react'
import axios from 'axios'
import config from 'common/config'

const reportsManagerUrl = config.Dashboard.reportsManagerUrl
const DashboardContext = createContext()


export default function DynamicDashboardProvider ({ children }) {
  let dashboards = {}
  const [publishedDashboards, setPublishedDashboards] = useState(dashboards)

  useEffect(() => {
    let request = JSON.stringify({ 'query': '{findAllDashboard{ id name assignTo published dashboardItems{ id name vizState layout}}}' })
    axios.post(reportsManagerUrl + '/graphql', request)
      .then((response) => {
        if (response.status === 200) {
          response.data.data.findAllDashboard.map((dashboard) => {
            if (!dashboards[dashboard.assignTo]) {
              dashboards[dashboard.assignTo] = []
            }
            if (dashboard.published) {
              dashboards[dashboard.assignTo].push({ id: dashboard.id, name: dashboard.name })
            }
          })
          setPublishedDashboards(dashboards)
          // console.log('published dashboards =', JSON.stringify(publishedDashboards))
        }
      }).catch((e) => console.log('error', e))
  }, [])
  return (
    <DashboardContext.Provider value={publishedDashboards}>
      {children}
    </DashboardContext.Provider>
  )
}

export const useDynamicDashboards = () => useContext(DashboardContext)
