import React from 'react';
import AppComponent from '../AppComponent';

const Home = React.lazy(() => import('./Home'))

class Splash extends AppComponent {
  static appDescriptor = {
    name: 'Select App',
    url: '/app/splash',
    icon: 'cui-bookmark',
    aliases: ['Splash'],
    nav: {
      items: [
        {
          name: 'Application List',
          url: '/app/splash/home',
          icon: 'cui-share'
        }
      ]
    },
    routes: [
      { path: '/app/splash', exact: true, name: 'Splash', component: Home },
      { path: '/app/splash/home', name: 'Home', component: Home }
    ]
  };

  render () {
    return <div>Entering Eureka Home ...</div>
  }
}

export default Splash
