import React from 'react'
import AppComponent from '../AppComponent'

const Site = React.lazy(() => import('./Site'))

class RewardNow extends AppComponent {
    static appDescriptor = {
      name: 'Reward Now',
      url: '/app/rewardnow',
      icon: 'cui-bookmark',
      nav: {
        items: [
          {
            name: 'Site',
            url: '/app/rewardnow/site',
            icon: 'cui-share'
          }
        ]
      },
      routes: [
        { path: '/app/rewardnow', exact: true, name: 'Reward Now', component: Site },
        { path: '/app/rewardnow/site', name: 'Site', component: Site }
      ]
    }

    render () {
      return <div>Entering Eureka Reference ...</div>
    }
}

export default RewardNow
