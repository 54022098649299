import React, { Component } from 'react'
import { CardHeader, CardBody, Card } from 'reactstrap'
import BarChart from '../../App/Explorer/Charts/BarChart'

class MeteringHistory extends Component {
  render () {
    let { response } = this.props
    let chartName = { 'chartLabel': 'Action Metering' }
    let chartJSX
    if (response != null && Object.keys(response).length !== 0 && response.aggregations != null) {
      let data = response.aggregations
      let buckets = data.dataFieldValue.buckets.map(function (item) {
        if (item.key_as_string != null) {
          return { 'key': item.key_as_string, 'doc_count': item.weight.value }
        } else {
          return item
        }
      })
      data.dataFieldValue.buckets = buckets
      chartJSX = (<div>
        <Card key="action">
          <CardHeader> ACTION HISTORY </CardHeader>
          <CardBody><BarChart aggregations={data} chartLabel={chartName} width="500" height="400" /></CardBody>
        </Card>
      </div>)
    } else if (response == null) {
      return <div></div>
    } else if (Object.keys(response).length === 0 || response.aggregations == null) {
      return (<div style={{ color: 'red', fontSize: '14px' }}>
        No Data found for the given input
      </div>)
    }
    return (
      <div>{chartJSX}</div>
    )
  }
}

export default MeteringHistory
