import 'react-app-polyfill/ie9' // For IE 9-11 support
import 'react-app-polyfill/ie11' // For IE 11 support
import './polyfill'
import React from 'react'
import ReactDOM from 'react-dom'

import { stopReportingRuntimeErrors } from "react-error-overlay";

import 'leaflet/dist/leaflet.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-rangeslider/lib/index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { Provider } from 'react-redux'
import store from './store/store'
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css'
import 'react-toastify/dist/ReactToastify.css'
import config from 'common/config'
import { CubeProvider } from '@cubejs-client/react'
import cubejs from '@cubejs-client/core'

const API_URL = config.Dashboard.reportsQueryEngineUrl
const CUBEJS_TOKEN = config.Dashboard.CUBEJS_TOKEN
const cubejsApi = cubejs(CUBEJS_TOKEN, {
  apiUrl: `${API_URL}/cubejs-api/v1`,
})

if (process.env.NODE_ENV === "development") {
    stopReportingRuntimeErrors(); // disables error overlays
}

// React Render
ReactDOM.render(
  <CubeProvider cubejsApi={cubejsApi}>
    <Provider store={store}>
      <App />
    </Provider>
  </CubeProvider>, document.getElementById('root')
)
if (module.hot) {
  module.hot.accept()
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
