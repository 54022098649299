import React, { Component, Suspense } from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import {
  Container
} from 'reactstrap'

import {
  AppAside,
  AppFooter,
  AppHeader,
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarHeader,
  AppSidebarMinimizer,
  AppSidebarNav2 as AppSidebarNav
} from '@coreui/react'

import DefaultHeader from '../../../containers/DefaultLayout/DefaultHeader'
import DefaultAside from '../../../containers/DefaultLayout/DefaultAside'
import DefaultFooter from '../../../containers/DefaultLayout/DefaultFooter'
import ManageAccount from '../ManageAccount/Account'
import Users from './Users'
import { AuthContextConsumer } from 'auth/AuthContext'
import Products from '../Products/Products'
import Roles from '../Roles/Roles'
import AdminLinks from 'containers/DefaultLayout/AdminLinks'

class ManageUsers extends Component {
  nav = {
    items: [...AdminLinks.links],
    routes: [
      {
        path: '/manage-users',
        exact: true,
        name: 'manage-users',
        component: Users,
        permissions: ['user:access:manage']
      },
      {
        path: '/manage-account',
        exact: true,
        name: 'manage-account',
        component: ManageAccount,
        permissions: ['user:access:manage']
      },
      {
        path: '/manage-products',
        exact: true,
        name: 'products',
        component: Products,
        permissions: ['user:access:manage']
      },
      {
        path: '/roles',
        exact: true,
        name: 'roles',
        component: Roles,
        permissions: ['user:access:manage']
      }
    ]
  };

  loading = () => (
    <div className="animated fadeIn pt-1 text-center">Loading...</div>
  );

  signOut (e, authState) {
    e.preventDefault()
    sessionStorage.removeItem('token')
    this.props.history.push('/')
    if (authState.keycloak) {
      authState.keycloak.logout()
    }
  }

  render () {
    return (
      <AuthContextConsumer>
        {() => {
          return (
            <div className="app">
              <AppHeader fixed>
                <Suspense fallback={this.loading()}>
                  <DefaultHeader
                    history={this.props.history}
                    appDropDownDisable
                  />
                </Suspense>
              </AppHeader>
              <div className="app-body">
                <AppSidebar fixed display="md">
                  <AppSidebarHeader />
                  <AppSidebarForm />
                  <Suspense>
                    <AppSidebarNav
                      navConfig={this.nav}
                      {...this.props}
                      router={Router}
                    />
                  </Suspense>
                  <AppSidebarFooter />
                  <AppSidebarMinimizer />
                </AppSidebar>
                <main className="main">
                  <Container fluid>
                    <Suspense fallback={this.loading()}>
                      <Route exact path="/manage-users" component={Users} />
                    </Suspense>
                  </Container>
                </main>
                <AppAside fixed>
                  <Suspense fallback={this.loading()}>
                    <DefaultAside />
                  </Suspense>
                </AppAside>
              </div>
              <AppFooter>
                <Suspense fallback={this.loading()}>
                  <DefaultFooter />
                </Suspense>
              </AppFooter>
            </div>
          )
        }}
      </AuthContextConsumer>
    )
  }
}

export default ManageUsers
