import React, { Component, Fragment, PureComponent } from 'react'
import './styles.scss'
import {
  Badge,
  Button, Card,
  Col,
  Form,
  FormGroup,
  FormText,
  Input,
  Label, Modal,
  ModalBody, ModalFooter,
  ModalHeader,
  Row,
  Table,
  CustomInput, CardFooter
} from 'reactstrap'
import CardHeader from 'reactstrap/es/CardHeader'
import CardBody from 'reactstrap/es/CardBody'
// import ManageroleRoles from './ManageroleRoles/ManageroleRoles'
// import ManageroleUsers from './ManageroleUsers/ManageroleUsers'
// import ManagerolePermissions from './ManagerolePermissions/ManagerolePermissions'
// import ManageSubroles from './ManageSubroles/ManageSubroles'
import Collapse from 'reactstrap/es/Collapse'
import axios from 'axios'
import toast from 'cogo-toast'
import config from '../../../common/config'
import ProductAndScopes from './ProductAndScopes'

class Editrole extends Component {
  constructor (props) {
    super(props)
    this.state = {
      accordionIndexOpen: 0,
      productAndScopesComponent: [],

      roleAndPermission: { 'productAndScopes': [] },
      basicInfo: { 'description': '', 'roleId': '' },
      hasError_description: false,
      hasError: false,
      dummy: 1

    }
  }

    onChange = (e) => {
      let key = e.target.dataset.key
      let value = e.target.value

      if ((!this.isValidText(value, 'hasError_' + key))) {
        this.setHasErrorTrue()
      }

      this.setState(prevState => ({
        ...prevState,
        basicInfo: {
          ...prevState.basicInfo,
          [key]: value
        }
      }))
    }

  toggle = (e) => {
    let accordionIndex = Number(e.target.dataset.event)
    this.setState({
      accordionIndexOpen: accordionIndex
    })
  }

  componentDidUpdate (prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS): void {
    if (prevProps.role !== this.props.role) {
      this.setState({
        roleAndPermission: this.props.role
      }, () => this.productAndScopes())
      let basicInfo = {}
      basicInfo['roleId'] = 'ROLE_' + this.props.role.roleName
      basicInfo['description'] = this.props.role.roleDescription
      this.setState({ basicInfo: basicInfo })
    }

    if (prevProps.isEditRolesOpen !== this.props.isEditRolesOpen) {
      this.setState({ hasError: false })
    }
  }

    updateBasicInfo =() => {
      if (this.state.hasError_description) {
        this.setState({ hasError: true })
        return null
      }
      this.setState({ hasError: false })
      let payload = this.state.basicInfo

      axios
        .put(config.AccountPermissionManager.manageRoles.url + '/update-info', payload, {
          headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${sessionStorage.getItem('token')}`
          }
        })
        .then(resp => {
          if (resp.status === 200) {
            toast.success('Successfully updated')
            this.props.getRoleAndPrivilegesData()
          } else {
            toast.error('Something went wrong')
          }
        })
        .catch(err => toast.error('Something went wrong'))
    }

    setHasErrorTrue = () => {
      this.setState({ hasError_description: true })
    }

    productAndScopes = () => {
      let result = []
      let roleConfig = this.state.roleAndPermission

      roleConfig['productAndScopes'].map((config, index) => {
        result.push(
          <Card>
            <CardHeader>
              {config.productName}
            </CardHeader>
            <CardBody>
              <div className="form-inline" key={config.productId}>
                {config.scopes.map((scope, index2) => {
                  return (
                    <div key={scope.id}>
                      <FormGroup >
                        <Label for={ 'scope-' + scope.id} > {scope.name}</Label> &nbsp;
                        <CustomInput type='switch' id={'scope-' + scope.id} checked={scope.permission}
                          data-productid={config.productId}
                          data-scopeid={scope.id}
                          data-scopename={scope.name}
                          onClick={this.changePermission}
                          data-rolename={roleConfig.roleName}
                          data-checked={scope.permission}
                        ></CustomInput>
                      </FormGroup>
                    </div>
                  )
                })}
              </div>
            </CardBody>
          </Card>
        )
      })

      this.setState({ productAndScopesComponent: result }, () => {
      })

      return result
    }

    changePermission =(e) => {
      let change = e.target.dataset
      let data = {}
      data['productId'] = e.target.dataset.productid
      data['scopeId'] = e.target.dataset.scopeid
      data['scopeName'] = e.target.dataset.scopename
      data['roleName'] = e.target.dataset.rolename
      let addPermissionFlag = (e.target.dataset.checked === 'false')
      if (addPermissionFlag) {
        axios
          .post(config.AccountPermissionManager.manageRoles.url + '/add-permission', data, {
            headers: {
              'Content-Type': 'application/json;charset=UTF-8',
              Authorization: `Bearer ${sessionStorage.getItem('token')}`
            }
          })
          .then(response => {
            if (response.status === 200) {
              toast.success('Successfully added but please wait for few seconds...')
              this.setState({ dummy: 5 }, () => {
                this.props.modifyRoleAndPermission(change, this.props.role)
              })
            } else {
              toast.error('Something went wrong')
            }
          })
          .catch(err => toast.error('Something went wrong'))
      } else {
        axios
          .post(config.AccountPermissionManager.manageRoles.url + '/remove-permission', data, {
            headers: {
              'Content-Type': 'application/json;charset=UTF-8',
              Authorization: `Bearer ${sessionStorage.getItem('token')}`
            }
          })
          .then(response => {
            // this.props.modifyRoleAndPermission(change, this.props.role)
            if (response.status === 200) {
              toast.success('Successfully removed but please wait for few seconds...')
              this.setState({ dummy: 6 }, () => {
                this.props.modifyRoleAndPermission(change, this.props.role)
              })
            } else {
              toast.error('Something went wrong')
            }
          })
          .catch(err => toast.error('Something went wrong'))
      }
    }

    renderProductAndScopes = () => {
      let result = []

      if (this.state.roleAndPermission !== undefined) {
        for (let i = 0; i < this.state.roleAndPermission['productAndScopes'].length; i++) {
          let config = this.state.roleAndPermission['productAndScopes'][i]
          result.push(
            <Fragment>
              <Card>
                <CardHeader>
                  <Button className='accordion-manage-account-header-button'
                    data-event={i}
                    onClick={this.toggle}>
                    {config.productName}
                  </Button>
                </CardHeader>
                <Collapse isOpen={this.state.accordionIndexOpen === i}>
                  <CardBody>
                    <div className="form-inline" key={config.productId}>
                      <ProductAndScopes config={config} roleConfig={this.state.roleAndPermission}
                        changePermission={this.changePermission}></ProductAndScopes>
                    </div>
                  </CardBody>
                </Collapse>
              </Card>
            </Fragment>
          )
        }
      }
      return result
    }

    isValidText = (value, errorKey) => {
      if (value && !value.match(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/)) {
        this.setState({ [errorKey]: false })
        return true
      }
      this.setState({ [errorKey]: true })
      return false
    }

    render () {
      let roleConfig = this.props.role
      return (

        <div className='edit-role-container'>
          <Modal isOpen={this.props.isEditRoleOpen}
            toggle={this.props.editRoleToggle}
            className='modal-dialog modal-lg'
          >
            <ModalHeader toggle={this.props.editRoleToggle}>New / Edit role</ModalHeader>
            <ModalBody>
              <Form>
                <Card key='0'>
                  <CardHeader>
                    Basic Info
                  </CardHeader>

                  <CardBody>
                    <Form>
                      <FormGroup>
                        <Label for="name"><strong> Description </strong></Label>
                        <Input type="text" name="name" id="name" onChange={this.onChange} data-key='description' valid={!this.state.hasError_description} invalid={this.state.hasError_description} value={this.state.basicInfo['description']}/>
                      </FormGroup>
                    </Form>

                    { this.state.hasError &&
                                              <h6 className="error"> All the red fields are mandatory and special characters not allowed </h6> }
                  </CardBody>
                  <CardFooter>
                    <div align='right'>
                      <Button color="primary" onClick={this.updateBasicInfo}>Update</Button>
                    </div>
                  </CardFooter>

                </Card>
                <Card id="product-settings">
                  <CardHeader>
                        Services
                  </CardHeader>
                  <CardBody>

                    {this.renderProductAndScopes()}

                  </CardBody>
                </Card>
              </Form>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={this.props.editRoleToggle}>Save role</Button>{' '}
              <Button color="secondary" onClick={this.props.editRoleToggle}>Cancel</Button>
            </ModalFooter>

          </Modal>
        </div>
      )
    }
}

export default Editrole
