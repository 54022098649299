import React, { Component, Fragment } from 'react'
import '../styles.scss'
import {
  Badge,
  Button, Card, CardFooter,
  Col,
  Form,
  FormGroup,
  FormText,
  Input,
  Label, Modal,
  ModalBody, ModalFooter,
  ModalHeader,
  Row,
  Table
  , UncontrolledTooltip } from 'reactstrap'
import CardHeader from 'reactstrap/es/CardHeader'
import CardBody from 'reactstrap/es/CardBody'
// import ManageProductRoles from './ManageProductRoles/ManageProductRoles'
// import ManageProductUsers from './ManageProductUsers/ManageProductUsers'
// import ManageProductPermissions from './ManageProductPermissions/ManageProductPermissions'
// import ManageSubProducts from './ManageSubProducts/ManageSubProducts'
import ManageProductScopes from './ManageProductScopes'
import Collapse from 'reactstrap/es/Collapse'
import config from 'common/config'
import axios from 'axios'
import toast from 'cogo-toast'

import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import EditProductScope from './EditProductScope'
import RemoveAlert from '../RemoveAlert'

class EditProduct extends Component {
  constructor (props) {
    super(props)
    this.state = {
      accordionIndexOpen: 0,

      scopeName: null,
      ScopesList: [],
      isEditScopeOpen: false,
      editScopeModalData: null,
      basicInfo: { 'productId': '', 'newName': '' },
      visible: false,
      deleteItemDetail: {},
      hasError_scopeName: true,
      hasError: false,
      hasError_newName: false,
      hasError_basicInfo: false

    }
  }

    onChangeInfo = (e) => {
      // let stateVar = e.target.dataset.stateVar
      let key = e.target.dataset.key
      let value = e.target.value
      if ((!this.isValidText(value, 'hasError_' + key))) {
        this.setHasErrorBasicInfoTrue()
      }
      this.setState(prevState => ({
        ...prevState,
        basicInfo: {
          ...prevState.basicInfo,
          [key]: value
        }
      }))
    }

  onChange = (e) => {
    let value = e.target.value.trim()
    if ((!this.isValidTextScope(value, 'hasError_scopeName'))) {
      return null
    }
    this.setState({ scopeName: value })
  }

  createNewScope = () => {
    if (this.state.hasError_scopeName) {
      this.setState({ hasError: true })
      return null
    }
    this.setState({ hasError: false })
    let data = {}
    let scopeName = this.state.scopeName
    if (scopeName !== null) {
      scopeName = scopeName.trim()
      let productId = this.props.productId
      data['scopeName'] = scopeName
      data['productId'] = productId
      axios
        .post(config.AccountPermissionManager.manageProducts.url + '/add-scope', data, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`
          }
        })
        .then(resp => {
          if (resp.status === 200) {
            toast.success('Successfully created')
            this.setState(this.props.getProductsList(), this.getScopesList)
            this.setHasErrorTrue()
          } else {
            toast.error('Something went wrong')
          }
        })
        .catch(err => toast.error('Something went wrong'))
    }
  }

    setHasErrorBasicInfoTrue = () => {
      this.setState({ hasError_newName: true })
    }

    setHasErrorTrue = () => {
      this.setState({ hasError_scopeName: true })
    }

    componentDidUpdate (prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS): void {
      if (prevProps !== this.props) {
        this.getScopesList()
      }

      if (prevProps.productName !== this.props.productName) {
        let basicInfo = {}
        basicInfo['productId'] = this.props.productId
        basicInfo['newName'] = this.props.productName
        this.setState({ basicInfo: basicInfo })
      }
      if (prevProps.isEditProductOpen !== this.props.isEditProductOpen) {
        this.setState({ hasError_basicInfo: false })
      }
    }

    updateBasicInfo =() => {
      if (this.state.hasError_newName) {
        this.setState({ hasError_basicInfo: true })
        return null
      }
      this.setState({ hasError_basicInfo: false })
      let payload = this.state.basicInfo

      axios
        .put(config.AccountPermissionManager.manageProducts.url + '/update-info', payload, {
          headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${sessionStorage.getItem('token')}`
          }
        })
        .then(resp => {
          if (resp.status === 200) {
            toast.success('Successfully updated here')
            this.setState({ basicInfo: payload })
            this.props.getProductsList()
            this.props.getRoleAndPrivilegesData()
          }
        })
        .catch(err => console.log(err))
    }

    componentDidMount (): void {
      this.getScopesList()
    }

      editScopeToggle = () => {
        this.setState({
          isEditScopeOpen: !this.state.isEditScopeOpen
        })
      }

        getScopesList = () => {
          if (!this.props.productId) { return }
          axios
            .get(config.AccountPermissionManager.manageProducts.url + '/' + this.props.productId + '/policy', {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`
              }
            })
            .then(response => {
              let permissions = response.data
              let filteredResult = []
              let count = 1
              for (let i = 0; i < permissions.length; i++) {
                if (permissions[i].name.split('_')[0] === 'PERMISSION') {
                  let temp = {}
                  temp['permissionId'] = permissions[i].id
                  let scopeNameAndId = permissions[i].name.split(/_(.+)/)[1]
                  temp['scopeName'] = scopeNameAndId.split('_')[0]
                  temp['scopeId'] = scopeNameAndId.split('_')[1]
                  temp['sNo'] = count++
                  filteredResult.push(temp)
                }
              }
              this.setState({ ScopesList: filteredResult })
            })
            .catch(err => console.log(err))
        }

        getScopeDetail = (e) => {
          let permission = JSON.parse(e.target.dataset.id)
          this.setState({ editScopeModalData: permission }, this.editScopeToggle)
        }

        toggle = () => {
          this.setState({
            isEditScopeOpen: !this.state.isEditScopeOpen
          })
        }

        removeScope = (deleteItemDetail) => {
          let productId = deleteItemDetail['productId']
          let scopeId = deleteItemDetail['scopeId']
          let scopeName = deleteItemDetail['deleteItemName']
          let permissionId = deleteItemDetail['permissionId']
          let data1 = {}
          data1['productId'] = productId
          data1['scopeId'] = scopeId
          data1['scopeName'] = scopeName
          let data2 = {}
          data2['productId'] = productId
          data2['permissionId'] = permissionId
          axios
            .delete(config.AccountPermissionManager.manageProducts.url + '/scope-delete', { data: data1,
              headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                Authorization: `Bearer ${sessionStorage.getItem('token')}`
              }
            })
            .then(resp => {
              return resp
            })
            .then(resp => {
              let promises = []
              for (let i = 0; i < 2; i++) {
                promises.push(axios.delete(config.AccountPermissionManager.manageProducts.url + '/permission-delete', { data: data2,
                  headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    Authorization: `Bearer ${sessionStorage.getItem('token')}`
                  }
                }))
              }
              axios.all(promises, {
                headers: {
                  'Content-Type': 'application/json;charset=UTF-8',
                  Authorization: `Bearer ${sessionStorage.getItem('token')}`
                }
              })
              this.getScopesList()
            })
            .then(resp => {
              this.setState(this.props.getProductsList(), this.getScopesList)
              toast.success('Successfully removed')
            })
            .catch(err => toast.error('Something went wrong'))
          this.onDismiss()
        }

        actionsFormatter = (cell, row) => {
          return (
            <Fragment>
              <i className='icon-trash icon-cursor' id={`remove-${row.scopeId}`} data-scopeid={row.scopeId} onClick={(e) => { this.onDismiss(); this.setDeleteItemDetail(e) } }data-name={row.scopeName} data-permissionid={row.permissionId}></i>
              <UncontrolledTooltip placement='right' target={`remove-${row.scopeId}`} > Remove</UncontrolledTooltip>
            </Fragment>
          )
        }

    onDismiss = () => {
      this.setState({ visible: !this.state.visible })
    }

    setDeleteItemDetail = (e) => {
      let deleteItemDetail = {}
      deleteItemDetail['productId'] = this.props.productId
      deleteItemDetail['deleteItemName'] = e.target.dataset.name
      deleteItemDetail['scopeId'] = e.target.dataset.scopeid
      deleteItemDetail['permissionId'] = e.target.dataset.permissionid
      this.setState({ deleteItemDetail: deleteItemDetail })
    }

    isValidText = (value, errorKey) => {
      if (value && !value.match(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/)) {
        this.setState({ [errorKey]: false })
        return true
      }
      this.setState({ [errorKey]: true })
      return false
    }

    isValidTextScope = (value, errorKey) => {
      if (value && !value.match(/[!@#$%^&*()_+\-=\[\]{};'"\\|,.<>\/?]/)) {
        this.setState({ [errorKey]: false })
        return true
      }
      this.setState({ [errorKey]: true })
      return false
    }

    render () {
      const props = {
        product: this.props.product
      }
      const bsTableOptions = {
        sizePerPageList: [{
          text: '10', value: 10
        }, {
          text: '25', value: 25
        }, {
          text: '30', value: 30
        },{
          text: '50', value: 50
        }, {
          text: 'All', value: this.state.ScopesList.length
        }]
      };
      return (

        <Modal isOpen={this.props.isEditProductOpen}
          toggle={this.props.editProductToggle}
          className='modal-dialog modal-lg'
        >
          <ModalHeader toggle={this.props.editProductToggle}>New / Edit Service</ModalHeader>
          <ModalBody>

            <Card key='0'>
              <CardHeader>
                Basic Info
              </CardHeader>

              <CardBody>
                <Form>
                  <FormGroup>
                    <Label for="name"><strong> Name </strong></Label>
                    <Input
                      type="text"
                      name="name"
                      id="name"
                      onChange={this.onChangeInfo}
                      data-key='newName'
                      valid={!this.state.hasError_newName}
                      invalid={this.state.hasError_newName}
                      value={this.state.basicInfo['newName']}/>
                  </FormGroup>
                </Form>
                { this.state.hasError_basicInfo &&
                                          <h6 className="error">All the red fields are mandatory and special characters not allowed </h6> }
              </CardBody>
              <CardFooter>
                <div align='right'>
                  <Button color="primary" onClick={this.updateBasicInfo}>Update</Button>
                </div>
              </CardFooter>
            </Card>

            <Card key='1'>
              <CardHeader>

                <div className="flex-container-space-between">
                  <div > Scopes</div>

                  <div >
                    <div className="flex-container-space-around">
                      <div >
                        <Input
                          type='text'
                          onChange ={this.onChange}
                          valid={!this.state.hasError_scopeName}
                          invalid={this.state.hasError_scopeName}></Input>
                      </div>
                      <div>
                        <Button color="primary" onClick={this.createNewScope}><i
                          className="fa fa-plus-circle" aria-hidden="true" ></i> &nbsp; Add Scope</Button>{' '}
                      </div>
                    </div>
                  </div>
                </div>
                <div>

                  { this.state.hasError &&
                      <h6 className="error"> All the red fields are mandatory and special characters not allowed</h6> }

                </div>
              </CardHeader>
              <Collapse isOpen={this.state.accordionIndexOpen === 0}>
                <CardBody>

                  {/* <ManageProductScopes {...this.props}></ManageProductScopes> */}
                  <div>
                    <BootstrapTable
                      trClassName='manage-product-table-row'
                      options={bsTableOptions}
                      pagination
                      data={this.state.ScopesList}
                      striped
                      search
                      // exportCSV
                      hover
                      version='4'
                      headerContainerClass='manage-product-table-header'
                    >
                      <TableHeaderColumn isKey dataField="sNo"
                        thStyle={{ width: '5%', color: 'white' }}
                        tdStyle={{ width: '5%' }}
                      >
                               #
                      </TableHeaderColumn>
                      <TableHeaderColumn dataField="scopeName" dataSort
                        thStyle={{ width: '20%', color: 'white' }}
                        tdStyle={{ width: '20%' }}
                      >
                               Scope Name
                      </TableHeaderColumn>
                      <TableHeaderColumn dataFormat={this.actionsFormatter}
                        thStyle={{ width: '15%', color: 'white' }}
                        tdStyle={{ width: '15%' }}
                      >
                               Actions
                      </TableHeaderColumn>

                    </BootstrapTable>
                    <div>
                      <EditProductScope
                        editScopeToggle={this.editScopeToggle}
                        isEditScopeOpen={this.state.isEditScopeOpen}
                        productId={this.props.productId}
                        permission={this.state.editScopeModalData}
                      ></EditProductScope>
                      <RemoveAlert
                        visible={this.state.visible}
                        toggle={this.onDismiss}
                        deleteItemDetail = {this.state.deleteItemDetail}
                        deleteFunction={this.removeScope}
                      >
                      </RemoveAlert>
                    </div>
                  </div>

                </CardBody>
              </Collapse>
            </Card>

          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.props.editProductToggle} >Close</Button>
          </ModalFooter>
        </Modal>
      )
    }
}

export default EditProduct
