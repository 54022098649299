import React from 'react'
import AppComponent from '../AppComponent'

const Single = React.lazy(() => import('./Single'))
const BackTesting = React.lazy(() => import('./BackTesting'))

class Verification extends AppComponent {
  static appDescriptor = {
    name: 'Verification',
    url: '/app/verification',
    icon: 'icon-pin',
    nav: {
      items: [
        {
          name: 'Single',
          url: '/app/verification',
          icon: 'fas fa-map',
          permissions: ['lvod:features:single']
        },
        {
          name: 'Back Testing',
          url: '/app/verification/backtesting',
          icon: 'fas fa-map',
          permissions: ['lvod:features:backtesting']
        }
      ]
    },
    routes: [
      { path: '/app/verification', exact: true, name: 'Verification', component: Single, permissions: ['lvod:features:single'] },
      { path: '/app/verification/single', name: 'Single', component: Single, permissions: ['verification:view'] },
      { path: '/app/verification/backtesting', name: 'BackTesting', component: BackTesting, permissions: ['lvod:features:backtesting'] }
    ]
  }
  render () {
    return <div>Entering Eureka Location Verification ...</div>
  }
}

export default Verification
