import React from 'react'
import AppComponent from '../AppComponent'
import roles from '../../../auth/authRoles'

const Dashboard = React.lazy(() => import('./Dashbord'))
// const Audience = React.lazy(() => import('./Audience/Audience.js'))
const Campaign = React.lazy(() => import('./Campaign/Campaign.js'))
const AssetManager = React.lazy(() => import('./AssetManager/AssetManager.js'))
// const Manage = React.lazy(() => import('./CustomTable'))
const Reports = React.lazy(() => import('./Reports/Reports.js'))
const CampaignCreate = React.lazy(() =>
  import('./Campaign/CampaignCreate/CampaignCreate.js')
)

const CampaignEdit = React.lazy(() =>
  import('./Campaign/CampaignCreate/CampaignEdit')
)
const PredictiveDashboard = React.lazy(() =>
  import('./Reports/PredictiveDashboard/PredictiveDashboard.js')
)
const CampaignDashboard = React.lazy(() =>
  import('./Reports/CampaignDashboard/CampaignDashboard.js')
)
class Matchbox extends AppComponent {
  static appDescriptor = {
    name: 'Matchbox',
    url: '/app/matchbox',
    icon: 'icon-target',
    nav: {
      items: [
        // {
        //   name: 'Dashboard',
        //   url: '/app/matchbox/dashboard',
        //   icon: 'icon-speedometer'
        // },
        // {
        //   name: 'Asset Manager',
        //   url: '/app/matchbox/assetmanager',
        //   icon: 'icon-list'
        // },
        {
          name: 'Campaign',
          url: '/app/matchbox/campaign',
          icon: 'icon-note',
          permissions: ['matchbox:ui:view']
        },
        // {
        //   name: 'Audience',
        //   url: '/app/matchbox/audience',
        //   icon: 'icon-eye',
        //   permissions: ['matchbox:ui:view']
        // },
        // {
        //   name: 'Manage',
        //   url: '/app/matchbox/manage',
        //   icon: 'icon-pencil'
        // },
        // {
        //   name: 'Track',
        //   url: '/app/matchbox/track',
        //   icon: 'icon-pencil'
        // },
        {
          name: 'Reports',
          url: '/app/matchbox/reports',
          icon: 'icon-pie-chart',
          children: [
            {
              name: 'PredictiveDashboard',
              url: '/app/matchbox/reports/predictivedashboard',
              icon: 'icon-calculator',
              permissions: ['matchbox:ui:view']
            },
            {
              name: 'CampaignDashboard',
              url: '/app/matchbox/reports/campaigndashboard',
              icon: 'icon-pencil',
              permissions: ['matchbox:ui:view']
            }
          ]
        }

        // {
        //   name: 'PredictiveDashboard',
        //   url: '/app/matchbox/reports/predictivedashboard',
        //   icon: 'icon-pencil'
        // },
        // {
        //   name: 'CampaignDashboard',
        //   url: '/app/matchbox/reports/campaigndashboard',
        //   icon: 'icon-pencil'
        // }
      ]
    },
    routes: [
      {
        path: '/app/matchbox',
        exact: true,
        name: 'matchbox',
        component: Campaign,
        permissions: ['matchbox:ui:view']
      },
      {
        path: '/app/matchbox/dashboard',
        name: 'Dashboard',
        component: Dashboard,
        permissions: ['matchbox:ui:view']
      },
      {
        path: '/app/matchbox/assetmanager',
        name: 'Asset Manager',
        component: AssetManager,
        permissions: ['matchbox:ui:view']
      },
      { path: '/app/matchbox/campaign', name: 'Campaign', component: Campaign },
      // { path: '/app/matchbox/audience', name: 'Audience', component: Audience },
      // { path: '/app/matchbox/manage', name: 'Manage', component: Manage },
      {
        path: '/app/matchbox/campaigncreate',
        name: 'CampaignCreate',
        component: CampaignCreate,
        permissions: ['matchbox:ui:view']
      },
      {
        path: '/app/matchbox/campaignedit',
        name: 'CampaignEdit',
        component: CampaignEdit,
        permissions: ['matchbox:ui:view']
      },
      {
        path: '/app/matchbox/reports/predictivedashboard',
        name: 'PredictiveDashboard',
        component: PredictiveDashboard,
        permissions: ['matchbox:ui:view']
      },
      {
        path: '/app/matchbox/reports/campaigndashboard',
        name: 'CampaignDashboard',
        component: CampaignDashboard,
        permissions: ['matchbox:ui:view']
      }
    ]
  };
  render () {
    return <div>Entering Eureka matchbox ...</div>
  }
}

export default Matchbox
