import React, { Component, Fragment } from 'react'
import DefaultHeader from '../../../containers/DefaultLayout/DefaultHeader'

import {
  Button,
  Card,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Label,
  Row,
  CardBody, ModalFooter,
  Alert
} from 'reactstrap'
import { AppHeader } from '@coreui/react'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import axios from 'axios'
import config from 'common/config'
import './styles.scss'
import AddProduct from './AddProduct/AddProduct'
import EditProduct from './EditProduct/EditProduct'
import RemoveAlert from './RemoveAlert'
import toast from 'cogo-toast'
import UncontrolledTooltip from 'reactstrap/lib/UncontrolledTooltip'

class Products extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isEditProductOpen: false,
      isAddProductOpen: false,
      productDropdownOpen: false,
      productsList: [],
      editProductModalData: { 'id': '', 'name': '' },
      visible: false,
      deleteItemDetail: {}
    }
  }

  toggle= (e) => {
    let key = e.target.dataset.key
    this.setState({
      [key]: !this.state[key]
    })
  }

  editProductToggle = () => {
    this.setState({
      isEditProductOpen: !this.state.isEditProductOpen
    })
  }

  addProductToggle = () => {
    this.setState({
      isAddProductOpen: !this.state.isAddProductOpen
    })
  }

    productToggle = () => {
      this.setState({
        productDropdownOpen: !this.state.productDropdownOpen
      })
    }

    getProductsList = () => {
      axios
        .get(config.AccountPermissionManager.manageProducts.url, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`
          }
        })
        .then(response => {
          let filteredResult = response.data.map((product, index) => {
            return {
              sNo: index + 1,
              id: product.id,
              name: product.name,
              uri: product.resourceURIS,
              uriId: product.resourceId,
              productScopes: product.scopes.map(scope => {
                return {
                  'name': scope.name,
                  'scopeId': scope.id
                }
              })
            }
          })
          this.setState({ productsList: filteredResult })
        })
        .catch(err => console.log(err))
    }

    getProductDetail = (e) => {
      let info = {}
      info['id'] = e.target.dataset.id
      info['name'] = e.target.dataset.name
      this.setState({ editProductModalData: info }, this.editProductToggle())
    }

    removeSelectedProduct = (deleteItemDetail) => {
      console.log('### Trying to delete : ', JSON.stringify(deleteItemDetail))
      axios
        .delete(config.AccountPermissionManager.manageProducts.url + '/' + deleteItemDetail.productId, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`
          }
        })
        .then((resp) => {
          if (resp.status === 200) {
            toast.success('Successfully removed')
            this.getProductsList()
            this.onDismiss()
            this.fetchAllAccountIdsAndUpdateSubscription(deleteItemDetail)
          } else {
            toast.error('Something went wrong')
          }
        })
        .catch(err => toast.error('Something went wrong'))
    }

    fetchAllAccountIdsAndUpdateSubscription = (deleteItemDetail) => {
      console.log('### Fetching all accounts to update subscription')
      axios
        .get(config.AccountPermissionManager.manageAccounts.url, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`
          }
        })
        .then(response => {
          let apiResponse = response.data
          let count = 0
          for (let i = 0; i < apiResponse.length; i++) {
            if (apiResponse[i]['accountName'] !== 'SystemInternalAccount') {
              let temp = {}
              temp['sNo'] = ++count
              temp['id'] = apiResponse[i]['accountId']
              temp['name'] = apiResponse[i]['accountName']
              temp['productSubscription'] = apiResponse[i]['prodSubsList']
              for (let i = 0; i < temp.productSubscription.length; i++) {
                let productSubscription = JSON.parse(temp.productSubscription[i])
                if (productSubscription.id === deleteItemDetail.productId) {
                  this.deleteProductFromAccountSubscription(deleteItemDetail, temp)
                }
              }
            }
          }
        })
        .catch(err => console.log(err))
    }

    checkDeletingProductSubscriptionInAccount = (deletingProductInfo, accountProductsSubscription) => {
      let result = false

      return result
    }

    deleteProductFromAccountSubscription = (deletingProductInfo, accountProductsSubscription) => {
      let payload = {}
      payload['accountId'] = accountProductsSubscription.id
      let prodSubsList = []
      for (let i = 0; i < accountProductsSubscription.productSubscription.length; i++) {
        let productSubscription = JSON.parse(accountProductsSubscription.productSubscription[i])
        if (productSubscription.id !== deletingProductInfo.productId) {
          prodSubsList.push(accountProductsSubscription.productSubscription[i])
        }
        payload['prodSubsList'] = prodSubsList
        axios
          .put(
            config.AccountPermissionManager.manageAccounts.url + '/change-subs',
            payload,
            {
              headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                Authorization: `Bearer ${sessionStorage.getItem('token')}`
              }
            }
          )
          .then((resp) => {
          })
          .catch(err => console.log(err))
      }
    }

    scopesFormatter = (cell) => {
      // console.log('cell is ; ', cell)
      let result = []
      cell.map(scope => {
        result.push('<i>  ' + scope.name + '</i>')
      })
      return result
    }

    onCancel = () => {

    }
    actionsFormatter = (cell, row) => {
      return (
        <Fragment>
            &nbsp; &nbsp; &nbsp;<i className='icon-note icon-cursor' id={`edit-${row.id}`} data-id={row.id} data-name={row.name} onClick={this.getProductDetail} > </i> &nbsp;

          <UncontrolledTooltip placement='bottom' target={`edit-${row.id}`} > Edit Service</UncontrolledTooltip>
          <i className='icon-trash icon-cursor' id={`remove-${row.id}`} data-id={row.id} data-name={row.name} onClick={(e) => { this.onDismiss(); this.setDeleteItemDetail(e) } }></i>
          <UncontrolledTooltip placement='bottom' target={`remove-${row.id}`} > Remove</UncontrolledTooltip>

        </Fragment>
      )
    }

    componentDidMount (): void {
      this.getProductsList()
    }

    onDismiss = () => {
      this.setState({ visible: !this.state.visible })
    }

    setDeleteItemDetail = (e) => {
      let deleteItemDetail = {}
      deleteItemDetail['deleteItemName'] = e.target.dataset.name
      deleteItemDetail['productId'] = e.target.dataset.id
      this.setState({ deleteItemDetail: deleteItemDetail })
    }

    render () {
      return (
        <div className='app'>

          <div className="flex-row align-items-center manage-service-container">

            {/* <Container> */}
            <div className='manage-service-div'>
              <Card >
                <CardHeader>
                  <Row>
                    <Col><h4 style={{ paddingTop: '5px' }}>Manage Services</h4></Col>
                    <Col style={{ textAlign: 'right' }}><Button color="primary" onClick={this.toggle} data-key='isAddProductOpen'><i
                      className="fa fa-plus-circle" aria-hidden="true"></i> Add Service</Button></Col>
                  </Row>

                </CardHeader>
                <CardBody>
                  <BootstrapTable
                    trClassName='manage-product-table-row'
                    data={this.state.productsList}
                    striped
                    search
                    // exportCSV
                    hover
                    version='4'
                    ignoreSinglePage={true}
                    headerContainerClass='manage-product-table-header'
                  >
                    <TableHeaderColumn isKey dataField="sNo"
                      thStyle={{ width: '5%', color: 'white' }}
                      tdStyle={{ width: '5%' }}
                    >
                          #
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField="name" dataSort
                      thStyle={{ width: '20%', color: 'white' }}
                      tdStyle={{ width: '20%', whiteSpace: 'normal' }}
                    >
                        Name
                    </TableHeaderColumn>

                    <TableHeaderColumn dataField="uri"
                      thStyle={{ width: '20%', color: 'white' }}
                      tdStyle={{ width: '20%', whiteSpace: 'normal' }}
                    >
                          URI
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField='productScopes'
                      dataFormat={this.scopesFormatter}
                      thStyle={{ color: 'white' }}
                      tdStyle={{ whiteSpace: 'normal' }}
                    >
                        Scopes
                    </TableHeaderColumn>
                    <TableHeaderColumn dataFormat={this.actionsFormatter}
                      thStyle={{ width: '20%', color: 'white' }}
                      tdStyle={{ width: '20%' }}
                    >
                          Actions
                    </TableHeaderColumn>
                  </BootstrapTable>
                </CardBody>

              </Card>
              {/* </Container> */}
              <div>
              </div>
              <EditProduct
                editProductToggle={this.editProductToggle}
                isEditProductOpen={this.state.isEditProductOpen}
                productId={this.state.editProductModalData.id}
                productName = {this.state.editProductModalData.name}
                getProductsList={this.getProductsList}
              ></EditProduct>
              <AddProduct
                addProductToggle={this.addProductToggle}
                isAddProductOpen = {this.state.isAddProductOpen}
                getProductsList = {this.getProductsList}
              >

              </AddProduct>
              <RemoveAlert
                visible={this.state.visible}
                toggle={this.onDismiss}
                deleteItemDetail={this.state.deleteItemDetail}
                deleteFunction={this.removeSelectedProduct}
              >
              </RemoveAlert>
              {/* {JSON.stringify(this.state.productsList)} */}
            </div>

          </div>
        </div>
      )
    }
}

export default Products
