import React from 'react'
import config from 'common/config'
import moment from 'moment'
import axios from 'axios'

const districtLabel = config.PinPoint.districtLabel
const districtKey = config.PinPoint.districtKey
const subDistrictLabel = config.PinPoint.subDistrictLabel
const villageLabel = config.PinPoint.villageLabel
const subdistrictKey = config.PinPoint.subDistrictKey
const villageKey = config.PinPoint.villageKey

// =========================|
//    Get current date and  |
//    change the format     |
// =========================|

const DATE_FORMAT = 'YYYY-MM-DD'

export function getCurrentDate (minusOneDay) {
  const current = moment()

  return minusOneDay > 0 ? current.subtract(minusOneDay, 'days').format(DATE_FORMAT) : current.format(DATE_FORMAT)
}

const pad = num => (num > 9 ? '' : '0') + num

export function formatDate (date) {
  let dt, years, month, _date

  dt = new Date(date)
  years = dt.getFullYear()
  month = pad(dt.getMonth() + 1)
  _date = pad(dt.getDate())

  return `${years}-${month}-${_date}`
}

export const lower = string => (string && typeof string === 'string') ? string.toLowerCase() : ''
export const uppercase = string => (string && typeof string === 'string') ? string.toUpperCase() : ''

export function capitalize (string) {
  if (!string || typeof string !== 'string') return ''
  const textToCapitalize = lower(string)

  return textToCapitalize.replace(/(^\w|\s\w)/g, c => c.toUpperCase())
}

export function fixedNumber (num, fixed = 2) {
  if (!num || typeof num !== 'number') return
  return num.toFixed(fixed)
}

export function roundNumber (num) {
  if (!num || typeof num !== 'number') return
  return Math.round(num)
}

export function memoize (fn) {
  const cache = {}

  return (...args) => {
    let f = args[0]
    let s = args[1]
    const prefix = `${f}+${s}`

    if (prefix in cache) {
      return cache[prefix]
    } else {
      let result = fn(f, s)
      cache[prefix] = result
      return result
    }
  }
}

export function add (f, s) {
  if (!f || typeof f !== 'number' || !s || typeof s !== 'number') return
  return f + s
}

// ================
// |  Sort object |
// \==============|
export function sortObject (object = {}) {
  if(object !== null) {
    if (typeof object !== 'object') return
    return Object.keys(object).sort().reduce((prev, obj) => {
      prev[obj] = object[obj]
      return prev
    }, {})
  }
}

// ==========================
// |  Sort Object by tTime  |
// \=========================
export function sortObjectByHours (objectWithHours = {}) {
  if (typeof objectWithHours !== 'object') objectWithHours = {}

  const arrayOfObject = []

  for (const item in objectWithHours) {
    if (objectWithHours.hasOwnProperty(item)) {
      arrayOfObject.push({ tTime: parseInt(item.substring(11, 13)), value: objectWithHours[item] })
    }
  }

  function numberComparison (a, b, prefix = 'tTime') {
    let comparison = 0

    const arg1 = parseInt(a[prefix])
    const arg2 = parseInt(b[prefix])

    if (arg1 > arg2) comparison = 1
    else if (arg1 < arg2) comparison = -1

    return comparison
  }

  return arrayOfObject.sort(numberComparison).map(item => ({
    tTime: item.tTime < 10 ? `0${item.tTime}` : `${item.tTime}`,
    value: item.value
  }))
}

/* Use this for sort data in avg hau weekdays response */
export function sortDateInChartWithHours (objectWithHours = {}) {
  if (typeof objectWithHours !== 'object') return

  const tempArray = []
  for (const item in objectWithHours) {
    if (objectWithHours.hasOwnProperty(item)) {
      tempArray.push({ key: parseInt(item), value: objectWithHours[item] })
    }
  }

  function compareHours (a, b) {
    let theComparison = 0

    const firstHours = parseInt(a.key)
    const secondHours = parseInt(b.key)

    if (firstHours > secondHours) theComparison = 1
    else if (firstHours < secondHours) theComparison = -1

    return theComparison
  }

  return tempArray.sort(compareHours).map(ta => ({
    key: ta.key < 10 ? `0${ta.key}` : `${ta.key}`,
    value: ta.value
  }))
}

// ==========================
// |  Sort value as number  |
// \========================|
export function numberSorting (objectWithNumbers = {}, type = 'asc') {
  if (typeof objectWithNumbers !== 'object') objectWithNumbers = {}

  const arrayOfObject = Object.entries(objectWithNumbers)

  function compareValue (arg1, arg2) {
    switch (type) {
      case 'asc':
        return arg1[1] - arg2[1]
      case 'desc':
        return arg2[1] - arg1[1]
      default:
        break
    }
  }

  return arrayOfObject.sort(compareValue)
}

// ====================================
// |  Sort object and remove unknown  |
// \==================================|
export function sortAndRemoveUnknown (object = {}, stringToRemove = 'pct_unknown', type = 'asc') {
  if (typeof object !== 'object') object = {}

  if (stringToRemove in object) {
    delete object[stringToRemove]
  }

  return numberSorting(object, type)
}

// ===================
// |  Remove prefix  |
// \=================|
export function removePrefix (string, prefix = '') {
  if (!string || typeof string !== 'string') return

  if (string.startsWith(prefix)) {
    return string.replace(prefix, '')
  }
}

// ==================================
// |  Get Geoname base on dropdown  |
// \================================|
export function getGeoname (district, subdistrict, prefix = 'value') {
  if (subdistrict) {
    return subdistrict[prefix]
  } else if (district) {
    return district[prefix]
  } else {
    return ''
  }
}

// ==================================
// |  Get Geotype base on dropdown  |
// \================================|
export function getGeoType (district, subdistrict) {
  if (subdistrict) {
    if (subdistrict === subDistrictLabel) {
      return subdistrictKey
    } else if (subdistrict === villageLabel) {
      return villageKey
    }
  } else if (district === districtLabel) {
    return districtKey
  } else {
    return ''
  }
}

export function getKeyFromObject (obj) {
  if(obj !== null) {
    let object = sortObject(obj)
    let temp = Object.keys(object)
    let final = []
    for (let i = 0; i < temp.length; i++) {
      var stringTemp = temp[i]
      final.push(stringTemp)
    }
    return final
  }
}

export function getKeyFromObjectNotSort (obj) {
  let temp = Object.keys(obj)
  let final = []
  for (let i = 0; i < temp.length; i++) {
    var stringTemp = temp[i]
    final.push(stringTemp)
  }
  return final
}

export function getValueFromObject (obj) {
  let object = sortObject(obj)
  if (!object) return
  let temp = Object.values(object)
  return temp
}

export function getValueUniqueReach (obj) {
  let temp = Object.values(obj)
  console.log(temp)
  let final = []
  for (let i = 0; i < temp.length; i++) {
    var stringTemp = temp[i]
    final.push(stringTemp)
  }
  return final
}

export function checkDelta (array) {
  let parse = getValueFromObject(array)
  if (parse.length > 1) {
    let sign = Math.sign(parse[parse.length - 1] - parse[parse.length - 2])
    let diff = Math.abs(((parse[parse.length - 1] - parse[parse.length - 2]) / parse[parse.length - 2]) * 100).toFixed(0) + '%'
    switch (sign) {
      case -1:
        return <sup><i className="fa fa-caret-down" aria-hidden="true"></i> {diff}</sup>
      case 1:
        return <sup><i className="fa fa-caret-up" aria-hidden="true"></i> {diff}</sup>
      default:
        return <sup>-</sup>
    }
  }
}

export function debounce (fn = f => f, wait = 250, immediate) {
  let timeout

  return function () {
    // eslint-disable-next-line
    let context = this
    let args = arguments

    let later = function () {
      timeout = null
      // eslint-disable-next-line
      if (!immediate) fn.apply(context, args)
    }

    let callNow = immediate && !timeout
    clearTimeout(timeout)

    timeout = setTimeout(later, wait)
    if (!callNow) fn.apply(context, args)
  }
}

export function getGeoTypeDistrict (value) {
  if (value === districtLabel) {
    return districtKey
  } else {
    return 'district'
  }
}

export function getGeoTypeDistrictOrVillage (value) {
  let result = ''
  switch (value) {
    case subDistrictLabel:
      result = subdistrictKey
      break
    case villageLabel:
      result = villageKey
      break
    default:
      result = ''
      break
  }

  return result
}

export function countHeatmap (obj, color) {
  let count = 0
  var row = getValueFromObject(obj)
  for (let i = 0; i < row.length; i++) {
    if (row[i] === color) {
      count += 1
    }
  }

  return count
}

export function saveCache (key, obj) {
  const keySessionStorage = sessionStorage.getItem(key)

  if (!keySessionStorage) {
    sessionStorage.setItem(key, JSON.stringify(obj))
  } else {
    sessionStorage.removeItem(key)
    sessionStorage.setItem(key, JSON.stringify(obj))
  }
}

export function getCache (key) {
  return JSON.parse(sessionStorage.getItem(key))
}

export function deleteCache (key) {
  sessionStorage.removeItem(key)
}

export function isFloat (n) {
  return Number(n) === n && n % 1 !== 0
}

export function generateColors (length, arrOfColors) {
  let generatedColors = []
  let y = 0;
  for(let x = 0; x <= length - 1; x++){
    if(arrOfColors[y] === undefined){
      y = 0
    }
    generatedColors.push(arrOfColors[y]);
    y++;
  }

  return generatedColors
}

export function checkPermissions (list, permission) {
  return list.includes(permission)
  //return true
}

export function checkPermissionsFake (list, permission) {
  //return list.includes(permission)
  return true
}

export function inThousand (amount) {
  let digit = Math.round(amount).toString().length
  if (digit > 3) {
    return Math.floor(amount / 1000) + 'K'
  } else {
    return Math.round(amount)
  }
}

export function updateLocalStorageMetrics (id, date, response){
  let formattedDate = moment(date).format("YYYY-MM")
  let metrics = JSON.parse(localStorage.getItem(`PP_${id}.${formattedDate}`))
  if(metrics){
    let newMetrics = { ...metrics, ...response }
    localStorage.setItem(`PP_${id}.${formattedDate}`, JSON.stringify(newMetrics))
  } else {
    localStorage.setItem(`PP_${id}.${formattedDate}`, JSON.stringify(response))
  }
}

export function convertObjToArray(metric){
  let tmp = Object.keys(metric)
  let tmp2 = tmp.map((data, key) => {
        return ({
          key: tmp[key],
          value: metric[tmp[key]]
        })
      })
  return tmp2
}

export const axiosInstance = axios.create()

axiosInstance.interceptors.request.use(
  config => {
    const token = sessionStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    return config;
  },
  error => {
    Promise.reject(error)
});
