import React from 'react'
import AppComponent from '../AppComponent'

const Tokenize = React.lazy(() => import('./Tokenize'))
const Dashboard = React.lazy(() => import('./Dashboard'))
const PrivacyManager = React.lazy(() => import('./PrivacyManager'))
const SmartConsent = React.lazy(() => import('./SmartConsent'))
const AccessAuditor = React.lazy(() => import('./AccessAuditor'))
/*
*/
class PrivacyShield extends AppComponent {
  static appDescriptor = {
    name: 'Privacy Shield',
    url: '/app/privacyshield',
    icon: 'icon-shield',
    nav: {
      items: [
        {
          name: 'Dashboard',
          url: '/app/privacyshield/Dashboard',
          icon: 'icon-drop'
        },
        {
          name: 'Privacy Manager',
          url: '/app/privacyshield/privacymanager',
          icon: 'icon-pencil'
        },
        {
          name: 'Smart Consent',
          url: '/app/privacyshield/smartconsent',
          icon: 'icon-speech'
        },
        {
          name: 'Access Auditor',
          url: '/app/privacyshield/accessauditor',
          icon: 'icon-check'
        }
      ]
    },
    routes: [
      { path: '/app/privacyshield', exact: true, name: 'Privacy Shield', component: Tokenize },
      { path: '/app/privacyshield/dashboard', name: 'Dashboard', component: Dashboard },
      { path: '/app/privacyshield/privacymanager', name: 'Privacy Manager', component: PrivacyManager },
      { path: '/app/privacyshield/smartconsent', name: 'Smart Consent', component: SmartConsent },
      { path: '/app/privacyshield/accessauditor', name: 'Access Auditor', component: AccessAuditor },
      { path: '/app/privacyshield/tokenize', name: 'Tokenize', component: Tokenize }
    ]
  }

  render () {
    return <div></div>
  }
}

export default PrivacyShield
