
import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import toast from 'cogo-toast'
import { Button, Card, CardBody, CardHeader, Col, Label, Row } from 'reactstrap'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import axios from 'axios'
import config from 'common/config'
import './styles.scss'
import AddAccount from './AddAccount/AddAccount'
import RemoveAlert from '../Products/RemoveAlert'
import UncontrolledTooltip from 'reactstrap/lib/UncontrolledTooltip'

class Account extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isAddAccountOpen: false,
      accountDropdownOpen: false,
      accountsList: [],
      visible: false,
      editAccountModalData: null,
      deleteItemDetail: {}
    }
  }

  componentDidMount (): void {
    this.getAccountsList()
  }

    toggle = () => {
      this.setState({
        isAddAccountOpen: !this.state.isAddAccountOpen
      })
    }
    accountToggle = () => {
      this.setState({
        accountDropdownOpen: !this.state.accountDropdownOpen
      })
    }

    getAccountsList = () => {
      axios
        .get(config.AccountPermissionManager.manageAccounts.url, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`
          }
        })
        .then(response => {
          let filteredResult = []
          let apiResponse = response.data
          let count = 0
          for (let i = 0; i < apiResponse.length; i++) {
            console.log(' account is : ', JSON.stringify(apiResponse[i]))
            if (apiResponse[i]['accountName'] !== 'SystemInternalAccount') {
              let temp = {}
              temp['sNo'] = ++count
              temp['id'] = apiResponse[i]['accountId']
              temp['name'] = apiResponse[i]['accountName']
              temp['productSubscription'] = JSON.stringify(apiResponse[i]['prodSubsList'])
              filteredResult.push(temp)
            }
          }
          this.setState({ accountsList: filteredResult }, () => console.log(' accounts are : ', this.state.accountsList))
        })
        .catch(err => console.log(err))
    }

    removeAccount = () => {
      const { deleteItemDetail } = this.state
      axios
        .delete(config.AccountPermissionManager.manageAccounts.url + '/' + deleteItemDetail.accountId, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`
          }
        })
        .then((resp) => {
          if (resp.status === 200) {
            toast.success(' Successfully deleted account ', deleteItemDetail.accountId)
            this.getAccountsList()
            this.onDismiss()
          } else {
            toast.error(' Something went wrong!! ')
          }
        })
        .catch(err => console.log(err))
    }

    onDismiss = () => {
      this.setState({ visible: !this.state.visible })
    }

    setDeleteItemDetail = (e) => {
      let deleteItemDetail = {}
      deleteItemDetail['deleteItemName'] = e.target.dataset.name
      deleteItemDetail['accountId'] = e.target.dataset.id
      this.setState({ deleteItemDetail: deleteItemDetail })
    }

    prodSubsFormatter = (cell, row) => {
      let result = []
      if (row.productSubscription === 'null') {
        return result
      }
      let prodSubs = JSON.parse(row.productSubscription)

      for (let i = 0; i < prodSubs.length; i++) {
        result.push('<i> ' + JSON.parse(prodSubs[i]).name + '</i>')
      }
      return result
    }
    actionsFormatter = (cell, row) => {
      console.log(' id is ', row.id)
      return (
        <Fragment>
          <Link to={{ pathname: '/edit-account', state: { accountId: row.id } }} replace> <i
            className='icon-note icon-cursor'> <Label className='edit-account-modal'
              data-id={row.id}></Label></i> </Link>

          <i className='icon-trash icon-cursor' id={`remove-${row.id}`} data-id={row.id} data-name={row.name}
            onClick={(e) => {
              this.onDismiss()
              this.setDeleteItemDetail(e)
            }}></i>
          <UncontrolledTooltip placement='bottom' target={`remove-${row.id}`}> Remove</UncontrolledTooltip>

        </Fragment>
      )
    }

    render () {
      const { visible, deleteItemDetail, accountsList, isAddAccountOpen } = this.state
      return (
        <div className='app'>

          <div className="flex-row manage-account-container">
            <div className='  manage-account-div'>

              <Card>
                <CardHeader>
                  <Row>
                    <Col><h4 style={{ paddingTop: '5px' }}>Manage Account</h4></Col>
                    <Col style={{ textAlign: 'right' }}><Button color="primary" onClick={this.toggle}><i
                      className="fa fa-plus-circle" aria-hidden="true"></i> Add Account</Button></Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <BootstrapTable
                    trClassName='manage-account-table-row'
                    data={accountsList}
                    striped
                    search
                    // exportCSV
                    hover
                    version='4'
                    ignoreSinglePage={true}
                    headerContainerClass='manage-account-table-header'
                  >
                    <TableHeaderColumn isKey dataField="sNo"
                      thStyle={{ width: '5%', color: 'white' }}
                      tdStyle={{ width: '5%' }}
                    >
                                        #
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField="name" dataSort
                      thStyle={{ width: '20%', color: 'white' }}
                      tdStyle={{ width: '20%' }}
                    >
                                        Account Name
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField="productSubscription"
                      thStyle={{ color: 'white' }}
                      dataFormat={this.prodSubsFormatter}
                      tdStyle={{ whiteSpace: 'normal' }}
                    >
                                        Service Subscription
                    </TableHeaderColumn>
                    <TableHeaderColumn dataFormat={this.actionsFormatter}
                      thStyle={{ width: '20%', color: 'white' }}
                      tdStyle={{ width: '20%' }}
                    >
                                        Actions
                    </TableHeaderColumn>
                  </BootstrapTable>
                </CardBody>

              </Card>
            </div>
            <div>
              <AddAccount
                addAccountToggle={this.toggle}
                isAddAccountOpen={isAddAccountOpen}
                getAccountsList={this.getAccountsList}
              ></AddAccount>
              <RemoveAlert
                visible={visible}
                toggle={this.onDismiss}
                deleteItemDetail={deleteItemDetail}
                deleteFunction={this.removeAccount}
              >
              </RemoveAlert>

            </div>
          </div>

        </div>
      )
    }
}

export default Account
