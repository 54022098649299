import React from 'react'
import {
  AppNavbarBrand,
  AppSidebarToggler
} from '@coreui/react'
import logo from '../../assets/img/brand/eureka_logo.png'
import sygnet from '../../assets/img/brand/eureka-brand.png'
export default function EurekaBrandLogo () {
  const reload = 'http://' + window.location.hostname + ':' + window.location.port
  return (
    <React.Fragment>
      <AppSidebarToggler className="d-lg-none" display="md" mobile />
      <AppNavbarBrand
        href={reload}
        full={{ src: logo, width: 89, height: 25, alt: 'Eureka Logo' }}
        minimized={{
          src: sygnet,
          width: 30,
          height: 30,
          alt: 'Eureka Logo'
        }}
      />
      <AppSidebarToggler className="d-md-down-none" display="lg" />
    </React.Fragment>
  )
}
