import React, { Component } from 'react'
import '../styles.scss'
import {
  Badge,
  Button, Card,
  Col,
  Form,
  FormGroup,
  FormText,
  Input,
  Label, Modal,
  ModalBody, ModalFooter,
  ModalHeader,
  Row,
  Table
} from 'reactstrap'
import CardHeader from 'reactstrap/es/CardHeader'
import CardBody from 'reactstrap/es/CardBody'
// import ManageScopeRoles from './ManageScopeRoles/ManageScopeRoles'
// import ManageScopeUsers from './ManageScopeUsers/ManageScopeUsers'
// import ManageScopePermissions from './ManageScopePermissions/ManageScopePermissions'
// import ManageSubScopes from './ManageSubScopes/ManageSubScopes'
import Collapse from 'reactstrap/es/Collapse'
import axios from 'axios'
import toast from 'cogo-toast'
class EditScope extends Component {
  constructor (props) {
    super(props)
    this.state = {
      accordionIndexOpen: 1,
        newScopeName : ''
    }
  }


  onScopeNameChange = (e) => {
        // let stateVar = e.target.dataset.stateVar
        let newScopeName = e.target.value
        this.setState({
            newScopeName : newScopeName
        })
    }
  toggle = (e) => {
    let accordionIndex = Number(e.target.dataset.event)
    this.setState({
      accordionIndexOpen: accordionIndex
    })
  }


  changeScopeName = e => {
      let newScopeName = this.state.newScopeName
      if(newScopeName !== this.props.scopeName){

      }
  }
  componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS): void {
      if(prevProps.permission !== this.props.permission){
          console.log('in component did mount of edit scope selected scope is : ' + JSON.parse(this.props.permission))

      }
  }


    render () {
    let props = {
      scope: this.props.scope
    }
    return (

      <Modal isOpen={this.props.isEditScopeOpen}
        toggle={this.props.editScopeToggle}
        className='modal-dialog modal-lg'
      >
        <ModalHeader toggle={this.props.editScopeToggle} >New / Edit Scope</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for="scopeName"><strong>Scope Name</strong></Label>
              <Input type="text" name="clientId" id="scopeName" onChange={this.props.onChange} data-key='clientId' />
            </FormGroup>
          </Form>

        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={this.props.createNewScope}>Save Changes</Button>{' '}
          <Button color="secondary" onClick={this.props.editScopeToggle} >Cancel</Button>

        </ModalFooter>
      </Modal>

    )
  }
}

export default EditScope
