import React, { Fragment, Component } from 'react'
import {
  Progress
} from 'reactstrap'
class ProgressBar extends Component {
  constructor (props) {
    super(props)
    this.state = {

    }
  }
  componentDidUpdate (prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS): void {
    if (prevProps.progressCompleted !== this.props.progressCompleted) {

    }
  }

  render () {
    return (
      <Fragment>
        <div className='text-center' > {this.props.progressQuote} </div>
        <Progress value={this.props.progressCompleted}></Progress>
      </Fragment>
    )
  }
}

export default ProgressBar
