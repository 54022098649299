import React, { Component } from 'react'

class MeteringInfo extends Component {
  render () {
    let { response } = this.props
    let tableJSX
    if (response != null && Object.keys(response).length !== 0 && response.hits != null) {
      let responseCount = 0
      let data = response.aggregations
      let buckets = data.dataFieldValue.buckets
      var i
      for (i = 0; i < buckets.length; i++) {
        if (buckets[i].key_as_string) {
          responseCount += buckets[i].weight.value
        }
      }

      tableJSX = (<table className="table">
        <tbody>
          <tr>
            <td>
              <h3>Total Requests</h3>
              #No. of Requests
            </td>
            <td className="center green">{response.hits.total}</td>
          </tr>
          <tr>
            <td>
              <h3>Response Count</h3>
              #No. of Responses
            </td>
            <td className="center green">{responseCount}</td>
          </tr>
        </tbody>
      </table>)
    } else if (response == null) {
      tableJSX = <div></div>
    } else if (Object.keys(response).length === 0 || response.hits == null) {
      return (<div style={{ color: 'red', fontSize: '14px' }}>
        No Data found for the given input
      </div>)
    }
    return (
      <div>{tableJSX}</div>
    )
  }
}

export default MeteringInfo
