import React, { Component } from 'react'
import '../styles.scss'
import {
  Badge,
  Button, Card,
  Col,
  Form,
  FormGroup,
  FormText,
  Input,
  Label, Modal,
  ModalBody, ModalFooter,
  ModalHeader,
  Row,
  Table,
  Progress
} from 'reactstrap'
import CardHeader from 'reactstrap/es/CardHeader'
import CardBody from 'reactstrap/es/CardBody'
// import ManageProductRoles from './ManageProductRoles/ManageProductRoles'
// import ManageProductUsers from './ManageProductUsers/ManageProductUsers'
// import ManageProductPermissions from './ManageProductPermissions/ManageProductPermissions'
// import ManageSubProducts from './ManageSubProducts/ManageSubProducts'
import Collapse from 'reactstrap/es/Collapse'
import axios from 'axios'
import config from 'common/config'
import toast from 'cogo-toast'
import ProgressBar from './ProgressBar'

class EditProduct extends Component {
  constructor (props) {
    super(props)
    this.state = {
      accordionIndexOpen: 1,
      showProgressBar: false,
      newProductDetail: { },
      progressQuote: '',
      progressCompleted: 0,
      hasError_clientId: true,
      hasError_productURIS: true,
      hasError_scopes: true,
      hasError: false

    }
  }

    onChange = (e) => {
      // let stateVar = e.target.dataset.stateVar

      let data = this.state.newProductDetail
      let key = e.target.dataset.key
      let value = e.target.value.trim()

      if ((key !== 'productURIS' && !this.isValidText(value, 'hasError_' + key)) ||
            key === 'productURIS' && !this.isValidUri(value, 'hasError_' + key)
      ) {
        return null
      }

      if (key === 'productURIS') {
        let temp = []
        temp.push(value)
        value = temp
      }

      this.setState(prevState => ({
        ...prevState,
        newProductDetail: {
          ...prevState.newProductDetail,
          [key]: value
        }
      }))
    }

    sleep = (milliseconds) => {
      var start = new Date().getTime()
      for (var i = 0; i < 1e7; i++) {
        if ((new Date().getTime() - start) > milliseconds) {
          break
        }
      }
    }

    createNewProduct = () => {
      if (this.state.hasError_clientId ||
      this.state.hasError_scopes ||
          this.state.hasError_productURIS
      ) {
        this.setState({ hasError: true })
        return null
      }
      this.setState({ hasError: false })

      this.toggleProgressBar()
      let data = this.state.newProductDetail
      this.setProgressBarVal('Setting up your Product!! Please wait !!', 5)
      let productId = null
      axios
        .post(config.AccountPermissionManager.manageProducts.url, data, {
          headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${sessionStorage.getItem('token')}`
          }
        })
        .then(response => {
          if (response.data.status === 201) {
            this.setProgressBarVal('Created Resources and Scopes .. ', 30)
          }
          let pieces = response.data.location.split('/')
          productId = pieces[pieces.length - 1]
          return axios
            .post(config.AccountPermissionManager.manageProducts.url + '/' + productId + '/policies', {}, {
              headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                Authorization: `Bearer ${sessionStorage.getItem('token')}`
              }
            })
        })
        .then(response => {
          if (response.status === 201) { this.setProgressBarVal('Created Policies, You\'re halfway through!! ', 50) }
          return axios
            .get(config.AccountPermissionManager.manageProducts.url + '/' + productId, {
              headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                Authorization: `Bearer ${sessionStorage.getItem('token')}`
              }
            })
        })
        .then(response => {
          let createPermissionPromises = []
          this.setProgressBarVal('Creating Permissions!!', 50)
          response.data.map(scope => {
            createPermissionPromises.push(axios
              .post(config.AccountPermissionManager.manageProducts.url + '/' + productId + '/permission/' + scope.id, null, {
                headers: {
                  'Content-Type': 'application/json;charset=UTF-8',
                  Authorization: `Bearer ${sessionStorage.getItem('token')}`
                }
              }))
          })
          let scopeNumber = response.data.length
          this.setProgressBarVal('Creating Permissions!!', 60)
          // let progressCompleted = this.state.progressCompleted
          return axios.all(createPermissionPromises, {
            headers: {
              'Content-Type': 'application/json;charset=UTF-8',
              Authorization: `Bearer ${sessionStorage.getItem('token')}`
            }
          }).then(results => {
            results.forEach((response, index) => {
              let newProgress = ((index + 1) / scopeNumber) * 100 - 50 + this.state.progressCompleted
              if (newProgress < 95) { this.setProgressBarVal('Creating Permissions!!', newProgress) } else {
                this.setProgressBarVal('Almost Finished!!', newProgress)
              }
            })
          })
        })
        .then(response => {
          this.setProgressBarVal('Product Created Successfully!!', 100)
          this.props.getProductsList()
          this.sleep(1000)
          this.props.addProductToggle()
          this.toggleProgressBar()
          this.setProgressBarVal('', 0)
          this.setHasErrorTrue()
          toast.success('Successfully created')
        })
        .catch(err => toast.error('Something went wrong'))

      // this.props.addProductToggle()
    }

    setHasErrorTrue = () => {
      this.setState({ hasError_clientId: true,
        hasError_productURIS: true,
        hasError_scopes: true })
    }

    onUpdateScopes = e => {
      let value = e.target.value.split(',')
      let filteredValue = []
      value.map(scope => {
        let scopeTrimmed = scope.trim()
        if (scopeTrimmed && !this.isValidText(scopeTrimmed, 'hasError_scopes')) {
          return null
        }
        filteredValue.push(scopeTrimmed)
      })
      this.setState(prevState => ({
        newProductDetail: {
          ...prevState.newProductDetail,
          scopes: filteredValue
        }
      }))
    }
    toggleProgressBar = () => {
      this.setState({
        showProgressBar: !this.state.showProgressBar
      })
    }

    setProgressBarVal = (quote, progress) => {
      this.setState((state) => {
        return { progressCompleted: progress,
          progressQuote: quote
        }
      }, () => this.forceUpdate())
    }

  progressBar = () => {
    let result = (
      <div>
        <div className='text-center'> Creating Product !! </div>
        <Progress value={this.state.progressCompleted}> </Progress>
      </div>
    )
    return result
  }

  componentDidMount (): void {
    this.setProgressBarVal('', 0)
  }

  isValidText = (value, errorKey) => {
    if (value && !value.match(/[!@#$%^&*()_+\=\[\]{};'"\\|,.<>\/?]/)) {
      this.setState({ [errorKey]: false })
      return true
    }
    this.setState({ [errorKey]: true })
    return false
  }

  isValidUri = (value, errorKey) => {
    // var regexUri = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)/;

    if (value && !value.match(/[!@#$%^&()_+\-=\[\]{};':"\\|,.<>?]/)) {
      this.setState({ [errorKey]: false })
      return true
    }
    this.setState({ [errorKey]: true })
    return false
  }

  componentDidUpdate (prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS): void {
    if (prevProps.isAddProductOpen !== this.props.isAddProductOpen) {
      this.setHasErrorTrue()
    }
  }

  render () {
    let props = {
      product: this.props.product
    }
    return (

      <Modal isOpen={this.props.isAddProductOpen}
        toggle={this.props.addProductToggle}
        className='modal-dialog modal-lg'
      >
        <ModalHeader toggle={this.props.addProductToggle} >New / Edit Service</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>

              <Label for="productName"><strong>Product Name</strong></Label>
              <Input type="text" name="clientId" id="productName" onChange={this.onChange} data-key='clientId' valid={!this.state.hasError_clientId} invalid={this.state.hasError_clientId} />
            </FormGroup>
            <FormGroup>
              <Label for="productUri"> <strong> Product Uri </strong></Label>
              <Input type="text" name="clientURIs" id="productUri" onChange={this.onChange} data-key='productURIS' valid={!this.state.hasError_productURIS} invalid={this.state.hasError_productURIS}></Input>

            </FormGroup>
            <FormGroup>
              <Label for="productScopes"><strong>Scopes </strong></Label>
              <Input type="textarea" name="scopes" id="productScopes" onBlur={this.onUpdateScopes} data-key='scopes'
                valid={!this.state.hasError_scopes} invalid={this.state.hasError_scopes}
                placeholder='Scopes separated by comma'/>
            </FormGroup>
          </Form>

          { this.state.hasError &&
              <h6 className="error"> All the red fields are mandatory and special characters not allowed </h6> }

          {(this.state.showProgressBar) ? <ProgressBar progressQuote={this.state.progressQuote} progressCompleted={this.state.progressCompleted} /> : null }

        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={this.createNewProduct}>Create</Button>{' '}
          <Button color="secondary" onClick={this.props.addProductToggle} >Cancel</Button>

        </ModalFooter>

      </Modal>

    )
  }
}

export default EditProduct
