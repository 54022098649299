import React, { useContext } from "react";
import Apps from '../containers/DefaultLayout/Apps'
import { appAllowed } from "../auth/AuthContext";
import config from 'common/config'

import {
  APP_SPLASH,
  APP_EXPLORER,
  APP_INSTANT_CHECK,
  APP_MARKET_INTELLIGENCE,
  APP_PINPOINT,
  APP_PRIVACY_SHIELD,
  APP_REWARD_NOW,
  APP_MATCHBOX,
  APP_VERIFICATION
} from '../constants/app-types'
import { getPageIndex } from '../Utils/routing'

const page = config.LandingPage
const landingPageIndex = getPageIndex(page)

const initialState = {
  selectedApp: Apps[landingPageIndex]
}

function reducer (state = initialState, action) {
  switch (action.type) {
    case APP_EXPLORER:
      return Object.assign({}, state, {
        selectedApp: Apps[1]
      })
    case APP_INSTANT_CHECK:
      return Object.assign({}, state, {
        selectedApp: Apps[2]
      })
    case APP_MARKET_INTELLIGENCE:
      return Object.assign({}, state, {
        selectedApp: Apps[3]
      })
    case APP_PINPOINT:
      return Object.assign({}, state, {
        selectedApp: Apps[4]
      })
    case APP_PRIVACY_SHIELD:
      return Object.assign({}, state, {
        selectedApp: Apps[5]
      })
    case APP_REWARD_NOW:
      return Object.assign({}, state, {
        selectedApp: Apps[6]
      })
    case APP_MATCHBOX:
      return Object.assign({}, state, {
        selectedApp: Apps[7]
      })
    case APP_VERIFICATION:
      return Object.assign({}, state, {
        selectedApp: Apps[8]
      })
    case APP_SPLASH:
      return Object.assign({}, state, {
        selectedApp: Apps[0]
      })
    default:
      return state
  }
}

export default reducer
