import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  DropdownItem,
  UncontrolledTooltip, UncontrolledPopover, PopoverBody
} from 'reactstrap'
import axios from 'axios'
import Swal from 'sweetalert2'
import config from 'common/config'
import moment from 'moment'
import InfiniteScroll from 'react-infinite-scroll-component'
import './DefaultLayout.css'
import sortBy from 'lodash/sortBy'
const propTypes = {
  children: PropTypes.node
}

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  Authorization: `Bearer ${sessionStorage.getItem('token')}`
}
const defaultProps = {}

class DefaultAside extends Component {
  constructor(props) {
    super(props)

    this.toggle = this.toggle.bind(this)
    this.state = {
      activeTab: '1',
      notificationCount: 0,
      notificationData: [],
      dataSize: 0,
      totalPages: 1
    }
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      })
    }
  }

  componentDidMount() {
    this.fetchMoreData()
    // this.fetchNotification()
    try {
      setInterval(() => {
        this.fetchNotification()
        const { notificationData } = this.state
        let notificationUrl = this.notificationUrl()
        const url = notificationUrl + '?page=0&size=10'
        axios({
          method: 'GET',
          headers: headers,
          url: url
        }).then((response) => {
          let arr = []
          if (response.status === 200) {
            arr = response.data.content
            this.mergeArr(notificationData, arr)
          }
        }).catch(
          () => console.log('Could not fetch the notification')
        )
      }, 20000)
    } catch (e) { console.log(e) }
  }

  mergeArr = (notificationData, arr) => {
    arr = [...arr, ...notificationData]
    var clean = arr.filter((arr, index, self) =>
      index === self.findIndex((t) => ('' + t.id === '' + arr.id)))
    this.setState({ notificationData: clean })
  }

  fetchNotification = () => {
    let notificationUrl = this.notificationUrl()
    const url = notificationUrl + 'count-unread'
    axios({
      method: 'GET',
      headers: headers,
      url: url
    }).then((response) => {
      if (response.status === 200) {
        this.setState({ notificationcount: response.data })
      }
    }).catch(
      () => console.log('error')
    )
  }

  getUrl(baseUrl, pathToService) {
    baseUrl = baseUrl.endsWith('/')
      ? baseUrl.substring(0, baseUrl.length - 1)
      : baseUrl
    return baseUrl + pathToService
  }
  
  notificationUrl = () => {
    let notificationService
    if ('DefaultService' in config && 'notificationService' in config.DefaultService) {
      notificationService = config.DefaultService.notificationService
    }
    if (notificationService === undefined) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Notification service is not specified in the default config'
      })
      return
    }
    let notificationUrl = this.getUrl(
      notificationService.url,
      notificationService.getNotificationUrl
    )
    return notificationUrl
  }

  fetchMoreData = () => {
    const { dataSize, totalPages } = this.state
    if (totalPages > dataSize) {
      let notificationUrl = this.notificationUrl()
      const url = notificationUrl + '?page=' + dataSize + '&size=10'
      axios({
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${sessionStorage.getItem('token')}`
        },
        url: url
      }).then((response) => {
        if (response.status === 200) {
          this.setState({
            notificationData: this.state.notificationData.concat(response.data.content),
            totalPages: response.data.totalPages,
            dataSize: dataSize + 1
          })
        }
      }).catch(
        () => console.log('Could not fetch Notification')
      )
    }
  }

  removeAlert = (id, index) => {
    const { notificationData } = this.state
    let arrNotify = []
    arrNotify = notificationData
    arrNotify.splice(index, 1)
    const notificationUrl = this.notificationUrl()
    const URL = notificationUrl + id
    axios({
      method: 'DELETE',
      headers: headers,
      url: URL
    }).then((response) => {
      if (response.status === 200) {
        this.setState({ notificationData: arrNotify })
        this.fetchNotification()
      }
    })
      .catch(() => alert('Server Error. Could not delete the alert.'))
  }

  saveRead = (id, index, value) => {
    const { notificationData } = this.state
    notificationData[index].readFlag = value
    const notificationUrl = this.notificationUrl()
    const URL = notificationUrl + 'update-flag/' + id + '?key=read_flag&value=' + value
    axios({
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('token')}`
      },
      url: URL
    }).then((response) => {
      if (response.status === 200) {
        this.setState({ notificationData: notificationData },
          this.fetchNotification())
      } else {
        alert('Something went wrong. Please try later')
      }
    })
      .catch(() => alert('Server Error. Could not update the status of alert.'))
  }

  pinFlag = (id, index, value) => {
    const { notificationData } = this.state
    const notificationUrl = this.notificationUrl()
    notificationData[index].pinnedFlag = value
    const URL = notificationUrl + 'update-flag/' + id + '?key=pinned_flag&value=' + value
    axios({
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('token')}`
      },
      url: URL
    }).then((response) => {
      if (response.status === 200) {
        this.setState({ notificationData: sortBy(notificationData, 'pinnedFlag').reverse() }, this.fetchNotification())
      } else {
        alert('Something went wrong. Please try later')
      }
    })
      .catch(() => alert('Server Error. Could not update the status of alert.'))
  }

  render() {
    // eslint-disable-next-line
    const { children, ...attributes } = this.props
    const { notificationData, dataSize, notificationcount } = this.state
    const displayDropList = notificationData.map((item, index) => {
      let currDate = new Date()
      let displayDate = ''
      currDate = moment(currDate).format('DD-MM-YYYY')
      let dbDate = moment(item.date).format('DD-MM-YYYY')
      if (currDate === dbDate) {
        displayDate = moment(item.date).format('hh:mm')
      } else {
        displayDate = moment(item.date).format('DD-MM-YYYY hh:mm')
      }
      let path = item.alertMsg.match(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/g)

      return (<DropdownItem key={index + 1}
        className="widthSet">
        <div className="priority-align">
          <div className={(item.readFlag) ? 'readDate' : 'unReadDate'}>
            {displayDate}</div>
          {(item.priority === 'IMPORTANT')
            ? <span className="badge badge-danger">{item.priority}</span>
            : (item.priority === 'CRITICAL')
              ? <span className="badge badge-primary">{item.priority}</span>
              : (item.priority === 'MEDIUM')
                ? <span className="badge badge-info">{item.priority}</span>
                : <span className="badge badge-warning">{item.priority}</span>
          }
        </div>
        <div className="alertMsg">
          <p><span id={'text' + item.id}
            className={(item.readFlag) ? 'readAlert' : 'unReadAlert'}> {(path && path.length > 0) ? (<a href={path[0]}>{item.alertMsg}</a>)
              : (item.alertMsg)}</span></p>
          <UncontrolledPopover trigger="hover" placement="bottom" target={'text' + item.id}>
            <PopoverBody >{item.alertMsg}</PopoverBody>
          </UncontrolledPopover>
        </div>
        <div className="button-align">
          <a className={(item.pinnedFlag) ? 'pingbutton1' : 'pingbutton'}
            id={'button-pinged' + item.id}
            onClick={() => {
              this.pinFlag(item.id, index, !item.pinnedFlag)
            }}>
            <i className="icon-pin"></i></a>
          <UncontrolledTooltip
            placement="bottom"
            target={'button-pinged' + item.id}
          >
            {(item.pinnedFlag) ? <>Unpin Alert</> : <>Pin Alert</>}
          </UncontrolledTooltip>

          <a className={(item.readFlag) ? 'roundbutton1' : 'roundbutton'}
            id={'button-unread' + item.id}
            onClick={() => {
              this.saveRead(item.id, index, !item.readFlag)
            }}><i className="ion-ios-arrow-down"></i></a>
          <UncontrolledTooltip
            placement="bottom"
            target={'button-unread' + item.id}
          >
            {(item.readFlag) ? <>Mark as Unread</> : <>Mark as Read</>}
          </UncontrolledTooltip>
          <button
            type="button"
            className="btn btn-default"
            id={'button-delete' + item.id}
            onClick={() => {
              this.removeAlert(item.id, index)
            }}
          >
            <i className="fas fa-close"></i>{' '}
          </button>
          <UncontrolledTooltip
            placement="bottom"
            target={'button-delete' + item.id}
          >
            Clear Notifications
            </UncontrolledTooltip>
        </div>
      </DropdownItem>
      )
    })
    return (
      <div>
        <DropdownItem header tag="div" className="text-center">
          <strong>Notifications</strong>
        </DropdownItem>
        <div id="scrollableDiv" style={{ height: 830, overflow: 'auto' }}>
          <InfiniteScroll
            dataLength={dataSize}
            next={this.fetchMoreData}
            hasMore={true}
            loader={<h6 className="notification-text">No Available Notifications.</h6>}
            scrollableTarget="scrollableDiv"
          >
            {displayDropList}
          </InfiniteScroll>
        </div>
      </div>
    )
  }
}

DefaultAside.propTypes = propTypes
DefaultAside.defaultProps = defaultProps

export default DefaultAside
