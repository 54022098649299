import React from 'react'
import AppComponent from '../AppComponent'

const SingleScore = React.lazy(() => import('./SingleScore'))
const BatchRequest = React.lazy(() => import('./BatchRequest'))

export default class InstantCheck extends AppComponent {
  static appDescriptor = {
    name: 'Credit Score',
    url: '/app/instantcheck',
    icon: 'icon-check',
    aliases: ['Credit Score', 'CreditScore', 'InstantCheck', 'Instant Check'],
    nav: {
      items: [
        {
          name: 'Single',
          url: '/app/instantcheck/singlescore',
          icon: 'icon-drop',
          permissions: ['credit:score:features:single']
        },
        {
          name: 'Back Testing',
          url: '/app/instantcheck/batchrequest',
          icon: 'icon-pencil',
          permissions: ['credit:score:features:backtesting']
        }
      ]
    },
    routes: [
      {
        path: '/app/instantcheck',
        exact: true,
        name: 'Instant Check',
        component: SingleScore,
        permissions: ['credit:score:features:single']
      },
      {
        path: '/app/instantcheck/singlescore',
        name: 'Single Score',
        component: SingleScore,
        permissions: ['credit:score:features:single']
      },
      {
        path: '/app/instantcheck/batchrequest',
        name: 'Batch Request',
        component: BatchRequest,
        permissions: ['credit:score:features:backtesting']
      },
    ]
  };

  render () {
    return <div>Entering Eureka Instant Check ...</div>
  }
}
